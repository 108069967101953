import React, { useEffect, useState } from "react";
import { InputBase, InputAdornment, Typography } from "@material-ui/core";
import MagnifyingGlassIcon from "../assets/MagnifyingGlassIcon";
import XCircle from "../img/XCircle.png";
import '../styles/SearchBar.css';
import { useTranslation } from "react-i18next";

const CalendarSearchBar = ({ updateSearchTerm, eventsCountMessage }) => {
  const [inputValue, setInputValue] = useState("");
  const [maxWidth, setMaxWidth] = useState('calc(100% - 120px)');
  const [marginTop, setMarginTop] = useState('30px'); 
  const [marginLeft, setMarginLeft] = useState('0px'); 
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 563) {
        setMaxWidth('calc(100% - 70px)');
        setMarginTop('40px'); // Adjust margin top for smaller screens
        setMarginLeft('010px');
      } else if (window.innerWidth < 1100) { // Check if window width is less than 1100px
        setMaxWidth('calc(100% - 120px)');
        setMarginTop('40px'); // Adjust margin top for screens between 564px and 1100px
      } else {
        setMaxWidth('calc(100% - 120px)');
        setMarginTop('30px'); // Reset margin top for larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    updateSearchTerm(value);
  };

  const handleClearInput = () => {
    setInputValue("");
    updateSearchTerm("");
  };

  const { t } = useTranslation();

  return (
     <React.Fragment>
          <div
            style={{
              display: 'block',
              flexDirection: 'column',
              margin: 'auto',
              position: window.innerWidth < 768 ? 'fixed' : 'fixed',
              maxWidth: maxWidth,
              width:  window.innerWidth < 768 ? '430px' : '900px',
              top: 0,
              left: window.innerWidth < 768 ? '0%' : '52%', 
              transform:  window.innerWidth < 768 ? 'translateX(0%)' : 'translateX(-50%)',
              marginLeft: marginLeft,
              marginBottom:  window.innerWidth < 768 ? '20px' : '0px',
            }}
          >
            <InputBase
              className="MuiInputBase-root"
              startAdornment={
                <InputAdornment position="start">
                  <MagnifyingGlassIcon style={{ color: "#F4F4F4", marginRight: '10px' }} />
                  <div className="search-label" style={{ color: '#F4F4F4', fontFamily: 'Montserrat', fontSize: '25px', marginBottom: '40px', marginLeft: '10px', marginTop: '15px', width: '90px' }}>{t('Search')}</div>
                </InputAdornment>
              }
              endAdornment={
                inputValue && (
                  <InputAdornment position="end">
                    <img
                      src={XCircle}
                      alt="X Circle"
                      style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                      onClick={handleClearInput}
                    />
                  </InputAdornment>
                )
              }
              
              inputProps={{
                style: {
                  flex: 1,
                  height: '31.59px',
                  color: '#9295A3',
                  fontFamily: 'Montserrat',
                  fontSize: '25px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  paddingTop: '30px',
                  marginLeft: '-97px',
                  position: 'relative',
                },
              
              }}
              style={{
                display: 'flex',
                maxWidth: '910px',
                margin: 'auto',
                borderRadius: '26px',
                border: '1.5px solid #4A4F5E',
                alignItems: 'center',
                padding: '0 15px',
                marginTop: marginTop,
                marginLeft: marginLeft
              }}
              value={inputValue}
              onChange={handleInputChange}
            />
            {/* Conditionally render either assetsCountMessage or "All assets" */}
            {eventsCountMessage !== '' && (
              <Typography variant="body1" noWrap style={{ color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, paddingLeft: '15px', paddingTop: '5px'}}>
                {eventsCountMessage}
              </Typography>
            )}
            {eventsCountMessage === '' && (
              <Typography variant="h6" noWrap style={{ display: 'flex', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px', paddingLeft: '15px', paddingTop: '5px' }}>
                {t('All events')}
              </Typography>
            )}
          </div>
        </React.Fragment>
  );
};

export default CalendarSearchBar;