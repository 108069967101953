import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { IconButton, Paper, TextField, ThemeProvider, createTheme, makeStyles } from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@mui/material';
import '../styles/EventCalendar.css';
import CalendarSearchBar from './CalendarSearchBar';
import { useTranslation } from 'react-i18next';
import { TimePicker , LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Children, cloneElement } from "react";
import Notifications from './Notifications';


const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: "rgb(74, 79, 94)"
          },
          "&:hover $notchedOutline": {
            borderColor: "rgb(0, 208, 189)"
          },
          "&$focused $notchedOutline": {
            borderColor: "rgb(0, 208, 189)"
          },
      
        },
      },
      MuiFormLabel: { // Add this to change the color of the label
        root: {
          "&$focused": {
            color: 'white'
          },
          color: 'white'
    
        }
      },
      MuiInputLabel: {
        root: {
            "&$focused": {
              color: 'white'
            },
            color: 'white',

          }
      },
      MuiInputBase: {
        color: 'white',
      }
    }
  });
const useStyles = makeStyles((theme) => ({
    inputRoot: {
      color: 'white',
      "& .MuiAutocomplete-clearIndicator": {
        color: 'white',

        bottom: '6px' 
      },
      "& .MuiAutocomplete-popupIndicator": {
        color: 'white'
      }
    },
    timePicker: {
        color: 'white',
        width: '270px',
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "rgb(74, 79, 94)",
          },
          "&:hover fieldset": {
            borderColor: "rgb(0, 208, 189)",
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgb(0, 208, 189)",
          },
      
          paddingLeft: '10px',
          top: '16px',
          height: '55px'
        },
        "& .MuiInputLabel-root": {
            color: 'white',
            top: '15px'
      },
        "& .MuiInputBase-input": {
          color: 'white',
        },
        "& .MuiSvgIcon-root": {
            color: 'white'
        }
      },
  }));

function MergedEventCalendar({ userId, onAddEvent, selectedDate, selectedTime, setSelectedDate, setSelectedTime, eventTitle, setEventTitle, assets, setAssets, setEvents, contactName, setContactName , contactTypeName, setContactTypeName, contactTypeId, setContactTypeId, contacts, setContacts, setSelectedContact , selectedContact, notes, setNotes, selectedType, setSelectedType, setNotificationCount, updateNotificationCount}) {
  const [filteredEventsWithDates, setFilteredEventsWithDates] = useState([]);
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [openContactType, setOpenContactType] = useState(false);
  const [id, setId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsUpdated, setEventsUpdated] = useState(false);
  const [eventsCountMessage, setEventsCountMessage] = useState('');
  const [contactTypes, setContactTypes] = useState([]);
  const { t } = useTranslation();
  // Add a state for the original list of events
const [eventsWithDates, setEventsWithDates] = useState([]);
const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
const [notificationMessages, setNotificationMessages] = useState([]);
const [isSelectionUpdated, setIsSelectionUpdated] = useState(false);
const [asset, setAsset] = useState(null);
const localizer = momentLocalizer(moment);
const showNotification = (dateForNotification, timeForNotification, contactName, assetName) => {
  const timestamp = new Date().getTime(); // Generate current timestamp
  // Store only the latest message, replacing any previous ones, now including a timestamp
  setNotificationMessages([{ dateForNotification, timeForNotification, contactName, assetName, timestamp }]);
};

useEffect(() => {
  console.log(notificationMessages);
}, [notificationMessages]);

const currentTimestamp = new Date().getTime();

// Adding a 1-minute buffer to the current timestamp
const bufferTime = 60 * 1000; // 60 seconds * 1000 milliseconds

const filteredNotificationMessages = notificationMessages.filter(({ dateForNotification, timeForNotification }) => {
  console.log(`Original: ${dateForNotification} ${timeForNotification}`);

  const [day, month, year] = dateForNotification.split('/');
  const formattedDateForNotification = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

  // Split the time into components and convert to 24-hour format
  const [time, modifier] = timeForNotification.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = modifier === 'AM' ? '00' : '12';
  } else {
    hours = modifier === 'PM' ? String(parseInt(hours, 10) + 12) : hours;
  }

  // Ensure hours and minutes are two digits
  hours = hours.padStart(2, '0');
  minutes = minutes.padStart(2, '0');

  // Combine date and time strings into a single Date object
  const notificationDateTime = new Date(`${formattedDateForNotification}T${hours}:${minutes}`);

  console.log(`Parsed Date: ${notificationDateTime.toString()}, Current Timestamp: ${new Date(currentTimestamp).toString()}`);

  // Return only future notifications, considering the buffer
  return notificationDateTime.getTime() > (currentTimestamp + bufferTime);
});
const classes = useStyles();
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const updateSearchTerm = (newSearchTerm) => {
    // Convert the search term to lower case for case-insensitive matching
    const lowerCaseSearchTerm = newSearchTerm.toLowerCase();
  
    if (lowerCaseSearchTerm === '') {
      // If the search term is empty, reset the filtered events to the original list
      setEventsCountMessage(t('All events'));
      setFilteredEventsWithDates(eventsWithDates);
    } else {
      // Filter the events based on the search term
      const filteredEvents = eventsWithDates.filter((event) => {
        // Check if the event title matches the search term
        const titleMatch = event.title && event.title.toLowerCase().includes(lowerCaseSearchTerm);
  
        // Check if the event date matches the search term
        const dateMatch = event.start && moment(event.start).format('YYYY-MM-DD').includes(lowerCaseSearchTerm);
  
        // Check if the contact name matches the search term
        const contactMatch = event.contact_name && event.contact_name.toLowerCase().includes(lowerCaseSearchTerm);
  
        // Return true if the title, date or contact name matches the search term
        return titleMatch || dateMatch || contactMatch;
      });
      setEventsCountMessage(`${filteredEvents.length} ${filteredEvents.length === 1 ? t('event found') : t('events found')}`);
      // Update the state with the filtered events
      setFilteredEventsWithDates(filteredEvents);
    }
  };

// Function to fetch contact name
const fetchContactDetails = async (contact_id, asset_id) => {
    const response = await fetch(`${API_URL}/api/contacts/id/${contact_id}/asset/${asset_id}`);
    const data = await response.json();
    return { contact_id, name: data.name, typeName: data.type_name, typeId: data.type_id }; 
  };
  
  const handleSelect = async (event) => {
    setSelectedEvent(event);
    setSelectedDate(moment(event.start).format('YYYY-MM-DD'));
    setSelectedTime(moment(event.start).format('hh:mm A')); // Use 'hh:mm A' format
    setEventTitle(event.title);
    setAsset(assets.find(asset => asset.id === event.asset_id));
    setNotes(event.notes); // Add this line
    // Fetch and set the contact name
    console.log('Selected event:', event); // Log the selected event
    console.log('Asset ID:', event.asset_id); 
    console.log(event.contact_id); 
      // Log the event object
  console.log(event);
  const contactDetails = await fetchContactDetails(event.contact_id, event.asset_id); // Pass the asset_id
  console.log(contactDetails.typeName); 
    console.log(contactDetails.typeName); 
    setContactName(contactDetails.name);
    setContactTypeId(contactDetails.typeId); 
    setContactTypeName(contactDetails.typeName);
     setSelectedType(contactDetails.typeName); // Set selectedType state here
    setSelectedContact(contactDetails); // Add this line
    setIsSelectionUpdated(true);
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedEvent(null);
    setSelectedDate(moment(slotInfo.start).format('YYYY-MM-DD'));
    setSelectedTime(null);
    setEventTitle('');
    setAsset(null);
    setContactName('');
    setContactTypeName('');
    setNotes('');
    setIsSelectionUpdated(true);
  };
  useEffect(() => {
    fetch(`${API_URL}/api/contact_types`)
      .then(response => response.json())
      .then(data => setContactTypes(data))
      .catch(error => console.error('Error:', error));
  }, []);

const handleAddEvent = async (e) => {
  e.preventDefault();
  if (!selectedDate || !selectedTime) {
    alert('Please select a date and time.');
    return;
  }
  const parsedTime = moment(selectedTime, 'hh:mm A').format('HH:mm:ss');
  const dateTime = moment(`${selectedDate}T${parsedTime}`);
  if (!dateTime.isValid()) {
    alert('Invalid date or time.');
    return;
  }
  const date = dateTime.format('YYYY-MM-DD');
  const time = dateTime.format('HH:mm:ss');
  const dateForNotification = dateTime.format('DD/MM/YYYY');
  const timeForNotification = dateTime.format('h:mm A');
  const assetId = asset ? (asset.id ? asset.id : asset.asset_id) : null;
  const assetName = asset ? asset.name : "Unknown Asset"; // Assuming selectedAsset has a name property
  const contactName = selectedContact ? selectedContact.name : "Unknown Contact"; // Assuming selectedContact has a name property
// Corrected call to showNotification
  // Only increment notification count if the event is in the future
  if (dateTime.isAfter(moment())) {
    setNotificationCount(prevCount => prevCount + 1);
  }
  const event = {
    asset_id: assetId,
    title: eventTitle,
    date,
    time,
    contact_id: selectedContact ? selectedContact.contact_id : null,
    contact_name: contactName,
    type_name: contactTypeName,
    type_id: contactTypeId,
    notes: notes,
  };
  console.log('Selected asset:', asset);
  // Add or update a contact type relation
  if (selectedContact && contactTypeId && asset) {
    console.log('Adding or updating contact type relation:', contactTypeId, assetId);
    const method = selectedEvent ? 'PUT' : 'POST'; // Use PUT if an event is being edited, otherwise use POST
    await fetch(`${API_URL}/api/contacts/id/${selectedContact.contact_id}/types`, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type_id: contactTypeId, asset_id: assetId }),
    })
    .then(response => response.json())
    .then(async data => {
      const updatedContactDetails = await fetchContactDetails(selectedContact.contact_id);
      setSelectedContact(updatedContactDetails);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  if (selectedEvent) {
    // If an event is being edited, send a PUT request to update the event
    fetch(`${API_URL}/api/events/${selectedEvent.event_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    })
    
    .then(() => {
      setFilteredEventsWithDates(prevEvents => prevEvents.map(prevEvent => prevEvent.event_id === selectedEvent.event_id ? { ...prevEvent, ...event } : prevEvent));
      setSelectedEvent(null);
      setSelectedDate(null);
      setEventsUpdated(prevState => !prevState);
    })
    
    .catch((error) => {
      console.error('Error:', error);
    });
  } else {
    // If a new event is being added, send a POST request to create the event
// If a new event is being added, send a POST request to create the event
fetch(`${API_URL}/api/events`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(event),
})
.then(response => response.json())
.then(data => {
    // Use the event_id from the response here to add it to the list of events
    const newEventWithId = { ...event, id: data.event_id };
    setFilteredEventsWithDates(prevEvents => [...prevEvents, newEventWithId]);
    setSelectedDate(null);
    setEventsUpdated(prevState => !prevState);
    console.log("Event details:", newEventWithId);

    // Now that we have the event_id, we can send the message about the upcoming event
    return fetch(`${API_URL}/api/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        messageKey: 'upcomingEvent',
        data: {
          eventId: data.event_id, // Include the event_id in the message data
          dateForNotification: dateForNotification,
          timeForNotification: timeForNotification,
          contactName: contactName,
          assetName: assetName
        }
      }),
    });
})
.then(response => response.json())
.then(messageData => {
    console.log("Message sucessfully created");
})
.catch((error) => {
  console.error('Error:', error);
});
  }
};

const deleteEvent = (eventToDelete) => {
  fetch(`${API_URL}/api/events/${eventToDelete.event_id}`, {
    method: 'DELETE',
  })
  .then(() => {
    // Remove the deleted event from the state
    setFilteredEventsWithDates(prevEvents => prevEvents.filter(event => event.event_id !== eventToDelete.event_id));
    setSelectedEvent(null);
    setEventsUpdated(prevState => !prevState);
    setSelectedDate(null);

    // Decrease the notification count in the UI
    setNotificationCount(prevCount => prevCount - 1);

    // Update the count in localStorage
    const currentCount = parseInt(localStorage.getItem('notificationCount'), 10) || 0;
    const newCount = currentCount > 0 ? currentCount - 1 : 0; // Ensure the count doesn't go below 0
    localStorage.setItem('notificationCount', newCount.toString());
  })
  .catch((error) => {
    console.error('Error:', error);
  });
};


  // State variables
const [connectedAssets, setConnectedAssets] = useState([]);
const [otherAssets, setOtherAssets] = useState([]);
// Function to handle type selection
const handleSelectType = (type) => {
    setSelectedType(type);
  };

// Fetch assets connected to the selected contact and type when they change
useEffect(() => {
  if (selectedContact && selectedType && isSelectionUpdated) {
    console.log('Selected contact:', selectedContact);
    console.log('Selected type:', selectedType);
    fetch(`${API_URL}/api/data/${userId}/connected/${selectedContact.contact_id}/${selectedType}`)
      .then(response => response.json())
      .then(data => {
        console.log('Connected assets:', data);
        setConnectedAssets(data);
        console.log('connectedAssets state:', connectedAssets);
        // Reset the flag after fetching
        setIsSelectionUpdated(false);
      })
      .catch(error => console.error('Error:', error));
  }
}, [selectedContact, selectedType, isSelectionUpdated, userId]);
  
  
  // Fetch other assets when the component mounts
  useEffect(() => {
    if (selectedContact) {
      fetch(`${API_URL}/api/data/${userId}/other/${selectedContact.contact_id}`)
        .then(response => response.json())
        .then(data => {
          console.log('Other assets:', data);
          setOtherAssets(data);
          console.log('otherAssets state:', otherAssets);
        })
        .catch(error => console.error('Error:', error));
    }
  }, [selectedContact]);

  useEffect(() => {
    fetch(`${API_URL}/api/contacts/${userId}`)
    .then(response => response.json())
    .then(data => setContacts(data))
    .catch(error => console.error('Error:', error));
}, []);

useEffect(() => {
  fetch(`${API_URL}/api/events/user/${userId}`)
    .then(response => response.json())
    .then(data => {
      console.log('Fetched events:', data);
      const updatedEventsWithDates = data.map(event => {
        let dateWithoutTime = event.date.split('T')[0];
        let start = new Date(dateWithoutTime + 'T' + event.time);
        let end = new Date(dateWithoutTime + 'T' + event.time);
        if (isNaN(start.getTime()) || isNaN(end.getTime())) {
          console.error('Invalid date format:', dateWithoutTime, event.time);
          return null;
        }
        start.setDate(start.getDate() + 1);
        end.setDate(end.getDate() + 1);
        return {
          ...event,
          title: `${event.title}`, // Include time in the title
          start: start,
          end: end,
        };
      }).filter(event => event !== null);
      setEventsWithDates(updatedEventsWithDates);
      setFilteredEventsWithDates(updatedEventsWithDates);
    })
    .catch(error => console.error('Error:', error));
}, [userId, eventsUpdated]);

  useEffect(() => {
    if (selectedEvent && connectedAssets.length > 0 && otherAssets.length > 0) {
      console.log('Selected event asset_id:', selectedEvent.asset_id);
      console.log('Connected assets:', connectedAssets);
      console.log('Other assets:', otherAssets);
      const asset = [...connectedAssets, ...otherAssets].find(asset => asset.asset_id === selectedEvent.asset_id);
      console.log('Found asset:', asset);
      setAsset(asset);
    }
  }, [selectedEvent, connectedAssets, otherAssets]); 

  const TouchCellWrapper = ({ children, value, onSelectSlot }) =>
    cloneElement(Children.only(children), {
      onTouchEnd: () => onSelectSlot({ action: "click", slots: [value] }),
      style: {
        className: `${children}`
      }
    });
    
  const onSelectSlot = ({ action, slots }) => {
    console.log("onSelectSlot");
    if (action === "click") {
      console.log("click");
      // Assuming the first slot in the array is the one we're interested in
      // and that handleSelectSlot expects an object with a start property.
      const slotInfo = { start: slots[0] }; // Adjust this based on actual structure
      handleSelectSlot(slotInfo);
    }
    return false;
  };

  return (
<>
    <CalendarSearchBar updateSearchTerm={updateSearchTerm} eventsCountMessage={eventsCountMessage} />
    <div style={{paddingTop: window.innerWidth < 768 ? '45px' : '0px'}}>
        <div className="merged-container" style={{display:  window.innerWidth < 768 ? 'flex' : '', justifyContent:  window.innerWidth < 768 ? 'center' : ''  }}>
          <Calendar
            localizer={localizer}
            events={filteredEventsWithDates}
            startAccessor="start"
            endAccessor="end"
            style={{ height: window.innerWidth < 768 ? '450px' : '600px', width: window.innerWidth < 768 ? '320px' : '900px', marginLeft: window.innerWidth < 768 ? '0px' : '90px', display:  window.innerWidth < 768 ? 'flex' : '', justifyContent:  window.innerWidth < 768 ? 'center' : '' }}
            selectable={true}
            onSelectEvent={handleSelect} // Use handleSelect for onSelectEvent
            onSelectSlot={handleSelectSlot} 
            components={{
              dateCellWrapper: (props) => (
                <TouchCellWrapper {...props} onSelectSlot={onSelectSlot} />
              )
            }}
          />
          {selectedDate && (
            <Paper style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '1em',
              backgroundColor: '#1A2033',
              color: 'white',
              zIndex: 1000,
              width: window.innerWidth < 768 ? '100%' : '750px',
            }}>
  <form onSubmit={handleAddEvent} style={{height: window.innerWidth < 768 ? 'calc(100vh - 20px)' : ''}}>
  <ThemeProvider theme={theme}>
<TextField
  label="Date"
  type="text"
  value={selectedDate || ''} // Set the value prop
  InputProps={{
    readOnly: true,
    style: { color: '#ffffff' },

  }}
  variant="outlined"
  margin="normal"
/>
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <TimePicker 
    label="Time"
    value={selectedTime ? dayjs(selectedTime, 'hh:mm A') : null} // Convert selectedTime to dayjs object
    className={classes.timePicker}
    onChange={(newValue) => {
      setSelectedTime(dayjs(newValue).format('hh:mm A')); // Convert newValue to 'hh:mm A' format
    }}
    onOpen={() => setIsTimePickerOpen(true)}
    onClose={() => setIsTimePickerOpen(false)}
    renderInput={(params) => <TextField {...params} />}
  />
</LocalizationProvider>
<TextField
  label="Event Title"
  type="text"
  name="title"
  value={eventTitle || ''} // Set the value prop
  InputProps={{
    style: { color: '#ffffff' },
  }}
  required
  variant="outlined"
  margin="normal"
  onChange={(e) => setEventTitle(e.target.value)}
/>
<Autocomplete
  disablePortal
  open={openContact}
  onOpen={() => {
    setOpenContact(true);
  }}
  onClose={() => {
    setOpenContact(false);
  }}
  options={contacts}
  getOptionLabel={(option) => option.name}
  value={contacts.find((option) => option.name === contactName) || null}
  onChange={(event, newValue) => {
    setContactName(newValue ? newValue.name : null);
    setSelectedContact(newValue ? newValue : null);
  }}
  renderInput={(params) => <TextField {...params} className={classes.inputRoot} label="Contact Name" variant="outlined" margin="normal" InputProps={{
    ...params.InputProps,
    style: { color: '#ffffff' },
  }} />}
/>
<Autocomplete
  disablePortal
  open={openContactType}
  onOpen={() => {
    setOpenContactType(true);
  }}
  onClose={() => {
    setOpenContactType(false);
  }}
  options={contactTypes}
  getOptionLabel={(option) => option.type_name}
  value={contactTypes.find((option) => option.type_name === contactTypeName) || null}
  onChange={(event, newValue) => {
    setContactTypeName(newValue ? newValue.type_name : null);
    setContactTypeId(newValue ? newValue.type_id : null); // Update the contactTypeId state
    handleSelectType(newValue ? newValue.type_name : null);
  }}
  renderInput={(params) => <TextField {...params} className={classes.inputRoot} label="Contact Type" variant="outlined" margin="normal" InputProps={{
    ...params.InputProps,
    style: { color: '#ffffff' },
  }} />}
/>
<Autocomplete
  disablePortal
  open={open}
  onOpen={() => {
    setOpen(true);
  }}
  onClose={() => {
    setOpen(false);
  }}
  options={[...connectedAssets, ...otherAssets]}
  getOptionLabel={(option) => option.name}
  value={asset || null} // Set the value prop
  onChange={(event, newValue) => {
    setAsset(newValue);
  }}
  groupBy={(option) => connectedAssets.includes(option) ? 'Connected with' : 'Other'}
  disabled={!contactName || !contactTypeName} // Disable if either contactName or contactTypeName is not selected
  renderInput={(params) => <TextField {...params} className={classes.inputRoot} label="Asset" variant="outlined" margin="normal" InputProps={{
    ...params.InputProps,
    style: { color: '#ffffff' },
  }} InputLabelProps={{
    style: { color: (!contactName || !contactTypeName) ? 'gray' : 'white' },
  }} />}
/>
<TextField
  label="Notes"
  type="text"
  value={notes || ''} // Set the value prop
  InputProps={{
    style: { color: '#ffffff' },
  }}
  variant="outlined"
  margin="normal"
  onChange={(e) => setNotes(e.target.value)}
  fullWidth
/>
<IconButton type="submit" variant="contained" color="primary" style={{ margin: '1em 0', fontSize: '18px', fontFamily: 'Montserrat',  borderRadius: '5px', color: (!selectedDate || !selectedTime) ? 'gray' : 'white' }} disabled={!selectedDate || !selectedTime}>
  {selectedEvent ? 'Update Event' : 'Add Event'}
</IconButton>
{selectedEvent && (
                <IconButton onClick={() => deleteEvent(selectedEvent)} style={{ margin: '1em 0', fontSize: '18px', fontFamily: 'Montserrat', color:'red',  borderRadius: '5px' }}>
                  Delete Event
                </IconButton>
              )}
<IconButton 
  type="button" 
  onClick={() => {
    setSelectedDate(null);
    setSelectedTime(null);
    setEventTitle('');
    setContactName(null);
    setSelectedContact(null);
    setContactTypeName(null);
    setContactTypeId(null);
    setAsset(null);
    setNotes('');
    // Reset any other state variables related to the form here
  }} 
  style={{ position: 'absolute', right: 0, top: 0, color: 'white' }}
>
    <CloseIcon style={{ color: 'white', fontSize: '20px' }}/>
  </IconButton>
    </ThemeProvider>
  </form>
            </Paper>
          )}
        </div>
    </div>
    <div>
    {/* {filteredNotificationMessages.length > 0 && (
      <Notifications userId={userId}  updateNotificationCount={updateNotificationCount} initialMessages={filteredNotificationMessages.map(({ dateForNotification, timeForNotification, contactName, assetName }) => 
        `${t('You have an upcoming event on')} ${dateForNotification} ${t('at')} ${timeForNotification} ${t('with')} ${contactName} ${t('about')} ${assetName}`
      )} />
    )} */}
  </div>
    </>
  );
}

export default MergedEventCalendar;