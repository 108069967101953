import React, { useEffect, useState } from 'react';
import { Button, Typography, ThemeProvider, createTheme, ListItem, TextField, IconButton, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Envelope from '../assets/Envelope';
import CurrencyCircleDollar from '../assets/CurrencyCircleDollar';
import FilePdf from '../assets/FilePdf';
import '../styles/Settings.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddNewSelectionIcon from '../assets/AddNewSelectionIcon';
import UncheckCircle from '../assets/UncheckCircle';
import { Checkbox, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AssignmentIcon from '@mui/icons-material/Assignment';
import pica from 'pica'; 
import { CustomAddCancelButton } from '../shared/buttons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 208, 189)', // Set the main color for primary elements
    },
    secondary: {
      main: 'rgb(0, 208, 189)', // Set the main color for secondary elements
    },
  },
});

const useStyles = makeStyles({

  button: {
      color: '#FFF !important',
      '&:hover': {
          color: '#00D0BD !important',
      },
  },
  '@global': {
    '.css-p198qn-MuiFormLabel-root-MuiInputLabel-root': {
      transform: 'translate(14px, 5px) scale(1)', // adjust position
    },
  },
  underline: {
    '&:after': {
        borderBottom: '1px solid #00D0BD',
    },
    '&:before': {
      borderBottom: '1px solid rgb(43, 48, 59)',
  },
  '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
    borderBottom: '2px solid #2b303b',
  },
},
icon: {
  fill: '#FFF',
},
paper: {
  backgroundColor: '#2b303b', // Replace with your background color
  maxHeight: 120, // Set a maximum height
  overflow: 'auto', // Add scrolling
  overflowX: 'hidden', // Hide horizontal scrollbar
  color: '#FFF',
  '&::-webkit-scrollbar': {
    width: '3px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#4A4F5E',
    borderRadius: '15px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#4A4F5E',
    boxShadow: 'inset 0 0 5px grey',
  },
},
hoveredItem: {
  color: 'rgb(0, 208, 189)',
},
formControl: {
  width: '100%'
},
select: {
  color: '#FFF',
  fontWeight: 300,
  fontSize: '18px',
  fontFamily: 'Montserrat',
  '&:before': {
    borderBottom: '1px solid #2b303b', // Add this if you want to change the color of the underline
  },
  '&:after': {
    borderBottom: '1px solid #2b303b', // Add this if you want to change the color of the underline
  },
  '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
    borderBottom: '1px solid #2b303b',
  },
},
imageContainer: {
  display: 'flex',
  justifyContent: 'flex-start', // Changed from 'left' to 'flex-start'
  marginTop: '30px',
  width: '440px',
  // other styles...
},
imageUpload: {
  width: '400px',
  height: '340px',
  border: '2px dashed rgb(0, 208, 189)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  // other styles...
},

sideImage: {
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px', 
  height: '65px', 
  width: '85px',
  border: '2px dashed rgb(0, 208, 189)',
  justifyContent: 'center',
  alignItems: 'center',
},
uploadIcon: {
  fontSize: '48px',
  color: '#ccc',
  cursor: 'pointer',
},
dialogActions: {
  flexShrink: 0,
  justifyContent: 'center',
  width: '100%',
  marginTop: '40px'
},
dialogContent: {
    position: 'fixed',
    maxWidth: '940px',
    width: '100%',
    height: '100%',
    flexShrink: 0,
    top:' 0px',
    right: '0px',
    border: '1px solid #333840',
    background: '#1A2033',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '1008',
    margin: '0px',
    overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#4A4F5E',
    borderRadius: '15px'
  }
},
});

const Settings = ({ exportSelectedAssets, sendSelectedAssetsToBackend, selectedAssets, handleSelectionClick, assetTypes, userId, setAssets, onDeleteAsset, displayedAssetsCount, setSelectedAssets, handleAssign, assignModalOpen, setAssignModalOpen, permissions, onHideAsset}) => {
  const { t } = useTranslation();
  const [isEnvelopeHovered, setIsEnvelopeHovered] = useState(false);
  const [isDollarHovered, setIsDollarHovered] = useState(false);
  const [isPdfHovered, setIsPdfHovered] = useState(false);

  const [savedSelections, setSavedSelections] = useState([]);
  const [newSelectionName, setNewSelectionName] = useState('');
  const [isExpanded, setIsExpanded] = useState(false); // State to track expansion
  const [isSaveCurrentClick, setIsSaveCurrentClick] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newAsset, setNewAsset] = useState({ name: '', address: '', township: '', squareFootage: '',
   amenities: '', price: '', description: '', floor: '', 
   total_floor_count: '', AssetType: '', noOfRooms : '', 
   furniture: '', asset_condition: '', heating: '', rent: '', sale: '' });

   const [hoveredItem, setHoveredItem] = useState(null);
   const [saleRentStatus, setSaleRentStatus] = useState(""); // default value
   const [selectedAmeneties, setSelectedAmeneties] = useState([]); // default value
   const [amenitiesList, setAmenitiesList] = useState([]);
   const [isAmenitiesFetched, setIsAmenitiesFetched] = useState(false);
   const [checkedItems, setCheckedItems] = useState({});
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [hideModalOpen, setHideModalOpen] = useState(false);
   const [deleteErrorModalOpen, setDeleteErrorModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [assignee, setAssignee] = useState('');
   const [users, setUsers] = useState([]);

  // Add a new state variable to track whether the delete button has been clicked
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [highestUsedIndex, setHighestUsedIndex] = useState(0);

  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files).slice(0, 10); // Limit to 10 files
  
    files.forEach((file, fileIndex) => {
      const tempUrl = URL.createObjectURL(file);
      const imageObject = { file, tempUrl };
  
      if (index + fileIndex === 0) {
        setMainImage(imageObject); // Set the first file as the main image
      }
  
      // Update side images
      setSideImages(prevSideImages => {
        const updatedSideImages = [...prevSideImages];
        updatedSideImages[index + fileIndex] = imageObject;
        return updatedSideImages;
      });
    });
  
    setHighestUsedIndex(prevIndex => Math.min(prevIndex + files.length, 10)); // Ensure it doesn't exceed 10
  };


  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);
  const handleDeleteAssetClick = () => {
    if (displayedAssetsCount > 0) {
      setDeleteModalOpen(true);
    } else {
      setIsDeleteClicked(true);
      setDeleteErrorModalOpen(true);
    }
  };

  const handleHideAssetClick = ()  => {
    if (displayedAssetsCount > 0) {
      setHideModalOpen(true);
    } else {
      setIsDeleteClicked(true);
      setDeleteErrorModalOpen(true);
    }
  }

  const handleAssignAssetClick = () => {
    if (displayedAssetsCount > 0) {
      setAssignModalOpen(true);
    } else {
      setIsDeleteClicked(true);
      setDeleteErrorModalOpen(true);
    }
  };
   
// To this
const handleAssigneeChange = (event) => {
  setAssignee(event.target.value);
};


useEffect(() => {
  // Fetch the emails from the backend
  fetch(`${API_URL}/api/users`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(setUsers)
    .catch((error) => {
      console.error('Error:', error);
    });
}, []);

const handleDelete = async () => {
  console.log('Delete function called'); // Check if function is called
  console.log('Selected assets at start:', selectedAssets); // Check the state of selectedAssets

  // Send a DELETE request for each selected asset
  const deleteRequests = selectedAssets.map(asset =>
    fetch(`${API_URL}/api/data/${asset.id}`, { method: 'DELETE' })
  );
  try {
    // Wait for all requests to complete
    await Promise.all(deleteRequests);

    // Filter out the selected assets from the state
    const updatedAssets = amenitiesList.filter(asset =>
      !selectedAssets.some(selectedAsset => selectedAsset.id === asset.id)
    );
    const deletedAssetId = selectedAssets.map(asset => asset.id);
    // Update the state
    setAssets(updatedAssets);

    for (const asset of selectedAssets) {
      console.log('Checking asset for images:', asset);
      // Check if the images array exists and has at least one image
      if (asset.images && asset.images.length > 0) {
// Function to normalize URLs by removing query parameters
function normalizeUrl(url) {
  try {
    const urlObj = new URL(url);
    // Return the URL without the search/query parameters
    return urlObj.origin + urlObj.pathname;
  } catch (error) {
    console.error('Error normalizing URL:', error);
    return url; // Return the original URL if there's an error
  }
}

// Example usage in your loop
for (const asset of selectedAssets) {
  // Your existing code...
  for (const image of asset.images) {
    if (image.originalImageUrl) {
      // Normalize the URL before using it
      const normalizedImageUrl = normalizeUrl(image.originalImageUrl);
      if (!normalizedImageUrl.startsWith('https://assets-galary.s3.eu-north-1.amazonaws.com/img/')) {
        throw new Error('Invalid image URL');
      }
      const cache = await caches.open('asset-images');
      // Use the normalized URL for deletion
      await cache.delete(normalizedImageUrl);
      console.log(`Cache delete successful for ${normalizedImageUrl}`);
    }
  }
}
      } else {
        console.log('No images found for asset:', asset);
      }
    }
    // Call onDelete for each deleted asset
    deletedAssetId.forEach(id => onDeleteAsset(id));
    setSelectedAssets([]);
    // Close the delete modal
    handleDeleteModalClose();
  } catch (error) {
    console.error('Error:', error);
  }
};

   const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteErrorModalOpen(false);
};

const handleHideModalClose = () => {
  setHideModalOpen(false);
};
const handleHide = async () => {
  // Send a PUT request for each selected asset
  const hideRequests = selectedAssets.map(asset =>
    fetch(`${API_URL}/api/users/${userId}/assets`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ assetIds: [asset.id] }),
    })
  );

  try {
    // Wait for all requests to complete
    await Promise.all(hideRequests);

    // Call onHideAsset for each hidden asset
    selectedAssets.forEach(asset => onHideAsset(asset.id));

    // Filter out the hidden assets from the state
    const updatedAssets = amenitiesList.filter(asset =>
      !selectedAssets.some(selectedAsset => selectedAsset.id === asset.id)
    );

    // Update the state
    setAssets(updatedAssets);

    // Clear the selection
    setSelectedAssets([]);

    // Close the hide modal
    setHideModalOpen(false);
  } catch (error) {
    console.error('Error:', error);
  }
};

   useEffect(() => {
    // Fetch amenities data from the backend API
    fetch(`${API_URL}/api/filter/amenities`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch amenities data');
        }
        return response.json();
      })
      .then(data => {
        // Set the fetched amenities data in the state
        setAmenitiesList(data.amenities);
  
        // Update checkedItems
        setCheckedItems(data.amenities.reduce((obj, amenity) => {
          obj[amenity] = true;
          return obj;
        }, {}));
  
        // Update isAmenitiesFetched
        setIsAmenitiesFetched(true);
      })
      .catch(error => {
        console.error('Error fetching amenities data:', error);
      });
  }, []);

  useEffect(() => {
    if (isAmenitiesFetched) {
      setCheckedItems(
        amenitiesList.reduce((obj, amenity) => {
          obj[amenity] = selectedAmeneties.includes(amenity);
          return obj;
        }, {})
      );
    }
  }, [amenitiesList, selectedAmeneties, isAmenitiesFetched]);

  const handleAdd = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    // Function to handle the upload process for a single file
    const uploadImage = async (file) => {
      // Resize the image if necessary (assuming resizeAndUpload returns a File or Blob)
      const resizedImage = await resizeAndUpload(file, 400, 335); // Example max width and height
  
      // Fetch the pre-signed URL from the backend
      const presignedUrlResponse = await fetch(`${API_URL}/api/users/${userId}/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: customUUID() + '.jpeg', // Generate a unique file name
          contentType: 'image/jpeg',
        }),
      });
  
      if (!presignedUrlResponse.ok) {
        throw new Error('Failed to get the pre-signed URL');
      }
  
      const { presignedUrl, accessUrl } = await presignedUrlResponse.json();
  
      // Use the pre-signed URL to upload the file to S3
      const uploadSuccess = await uploadFileToS3(resizedImage, presignedUrl);
  
      if (!uploadSuccess) {
        throw new Error('Failed to upload file to S3');
      }
  
      return accessUrl;
    };
  
    // Upload the main image
    let mainImageUrl = '';
    if (mainImage && mainImage.file) {
      mainImageUrl = await uploadImage(mainImage.file);
    }
  
    // Upload side images, ensuring not to duplicate the main image if it's also the first side image
    const sideImageUrls = await Promise.all(sideImages.map(({ file }, index) => 
      index === 0 && mainImage && mainImage.file === file ? Promise.resolve(mainImageUrl) : uploadImage(file)
    ));
  

// Combine the main image URL with the side image URLs using a Set to ensure uniqueness
const allImageUrlsSet = new Set([mainImageUrl, ...sideImageUrls].filter(url => url));
const allImageUrls = [...allImageUrlsSet]; // Convert the Set back to an array

// Now, allImageUrls contains only unique URLs
const imageUrlString = allImageUrls.join(';'); // Join the URLs into a single string if needed


    const amenitiesArray = Object.keys(checkedItems).filter(key => checkedItems[key]);
    const amenitiesJSON = JSON.stringify(amenitiesArray);
    const noOfRooms = parseFloat(newAsset.noOfRooms).toFixed(1) || null;
    const formatDate = (date) => {
      if (!date) return null;
      const localDate = new Date(date);
      // Get the time zone offset in minutes and convert it to milliseconds
      const timeZoneOffset = localDate.getTimezoneOffset() * 60000;
      // Adjust the date to compensate for the time zone offset
      const adjustedDate = new Date(localDate.getTime() - timeZoneOffset);
      // Convert to UTC and format
      return adjustedDate.toISOString().slice(0, 10);
    };

    // Define the new asset data
    const newAssetData = {
      name: newAsset.name || null,
      township: newAsset.township || null,
      address: newAsset.address || null,
      squareFootage: newAsset.squareFootage || null,
      total_floor_count: newAsset.total_floor_count || null,
      floor: newAsset.floor || null,
      noOfRooms: noOfRooms || null,
      furniture: newAsset.furniture || null,
      asset_condition: newAsset.asset_condition || null,
      heating: newAsset.heating || null,
      description: newAsset.description || null,
      amenities: amenitiesJSON,
      AssetType: newAsset.AssetType || null,
      price: newAsset.price ? parseFloat(newAsset.price).toFixed(2) : null,
      imageUrl: imageUrlString,
      availability_start_date: formatDate(startDate), // Format and include start date
      availability_end_date: formatDate(endDate), // Format and include end date
    };
  
    // Make the API call
    const response = await fetch(`${API_URL}/api/data/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newAssetData)
    });
  
    if (response.ok) {
      // If the response was successful, clear the form and show a success message
      setNewAsset({ name: '', address: '', township: '', squareFootage: '',
        amenities: '', price: '', description: '', floor: '', 
        total_floor_count: '', AssetType: '', noOfRooms : '', 
        furniture: '', asset_condition: '', heating: '', rent: '', sale: '' }); // Reset the form to default empty values
      alert('Data successfully added.');
  
      setModalOpen(false);
      // Update the assets state to include the new asset
      // This assumes that the server response includes the new asset data
      const newAsset = await response.json();
      setAssets(prevAssets => [...prevAssets, newAsset]);
    } else {
      // If the response was not successful, show an error message
      const errorData = await response.json();
      alert(`Error adding data: ${errorData.error}`);
    }
  };
  const handleEnvelopeHover = () => {
    setIsEnvelopeHovered(true);
  };


  const handleEnvelopeMouseOut = () => {
    setIsEnvelopeHovered(false);
  };

  const handleDollarHover = () => {
    setIsDollarHovered(true);
  };

  const handleDollarMouseOut = () => {
    setIsDollarHovered(false);
  };

  const handlePdfHover = () => {
    setIsPdfHovered(true);
  };

  const handlePdfMouseOut = () => {
    setIsPdfHovered(false);
  };


  const handleSaveCurrent = () => {
      // If there are selected assets, proceed as normal
      setIsSaveCurrentClick(!isSaveCurrentClick);
      setIsExpanded(false);
  };
  

  const handleSaveButtonClick = () => {
    // Implement logic to save the selection
    setIsExpanded(!isExpanded);

  };

  useEffect(() => {
    const savedSelections = JSON.parse(localStorage.getItem('savedSelections')) || [];
    setSavedSelections(savedSelections);
  }, []); 
  const handleSaveNameButtonClick = () => {
    const newSavedSelections = [...savedSelections, { name: newSelectionName, assets: selectedAssets }];
    setSavedSelections(newSavedSelections);
    setIsSaveCurrentClick(!isSaveCurrentClick);
    localStorage.setItem('savedSelections', JSON.stringify(newSavedSelections));
    console.log(newSelectionName)
    console.log(selectedAssets)
  };

  const handleDeleteClick = (index) => {
    const newSavedSelections = savedSelections.filter((_, i) => i !== index);
    setSavedSelections(newSavedSelections);
    localStorage.setItem('savedSelections', JSON.stringify(newSavedSelections));
  };
  const handleSavedSelectionNameChange = (event) => {
    // Update the newSelectionName state based on user input
    setNewSelectionName(event.target.value);
  };

  const handleNewAssetClick = () => {
    // Implement logic to add a new asset
    setModalOpen(true);
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
    setNewAsset([]);
    setSaleRentStatus('');
    setSelectedAmeneties([]);

};

  const [mainImage, setMainImage] = useState(null);
  const [sideImages, setSideImages] = useState([]);

  const handleImageUploadClick = (type) => {
    document.getElementById(`${type}ImageUploadInput`).click();
  };

  function customUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c === 'x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  }
  const handleFileChangeMain = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const tempUrl = URL.createObjectURL(file); // Create a temporary URL for the UI
  
      // Update the UI to display the image
      setMainImage({ file, tempUrl }); // Store both the file object and the temporary URL
  
      // Assuming sideImages is an array in your state and setSideImages is the setter
      const newSideImages = [...sideImages];
      if (newSideImages.length > 0) {
        newSideImages[0] = { file, tempUrl }; // Update the first side image
      } else {
        newSideImages.push({ file, tempUrl }); // Add as the first side image if none exist
      }
      setSideImages(newSideImages); // Update the state
    }
  };
  

// Updated function to upload a file using a pre-signed URL and return success status
async function uploadFileToS3(file, presignedUrl) {
  try {
    console.log('Sending PUT request to S3');
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (response.ok) {
      console.log('File uploaded successfully');
      return true; // Return true to indicate success
    } else {
      console.error('Failed to upload file');
      return false; // Return false to indicate failure
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    return false; // Return false to indicate failure
  }
}
// Adjusted resizeAndUpload to return a Promise resolving with the blob
const resizeAndUpload = async (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error("No file provided"));
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = async () => {
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = img.width;
        offscreenCanvas.height = img.height;
        const ctx = offscreenCanvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
        const picaResizer = pica();

        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        const targetWidth = Math.round(img.width * ratio);
        const targetHeight = Math.round(img.height * ratio);

        try {
          const resizedBuffer = await picaResizer.resizeBuffer({
            src: imageData.data,
            width: imageData.width,
            height: imageData.height,
            toWidth: targetWidth,
            toHeight: targetHeight
          });

          // Create a new blob from the resized buffer
          const newImageData = new ImageData(new Uint8ClampedArray(resizedBuffer), targetWidth, targetHeight);
          const newCanvas = document.createElement('canvas');
          newCanvas.width = targetWidth;
          newCanvas.height = targetHeight;
          const newCtx = newCanvas.getContext('2d');
          newCtx.putImageData(newImageData, 0, 0);

          newCanvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/jpeg', 0.90);
        } catch (error) {
          console.error('Resize error:', error);
          reject(error);
        }
      };
    };
    reader.readAsDataURL(file);
  });
};

const handleFileChangeSide = (event, index) => {
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    const tempUrl = URL.createObjectURL(file); // Create a temporary URL for the UI

    // Assuming sideImages is an array in your state and setSideImages is the setter
    const newSideImages = [...sideImages]; // Make a shallow copy of the sideImages array
    newSideImages[index] = { file, tempUrl }; // Update the specific index with the new file object and temporary URL
    setSideImages(newSideImages); // Update the state

    // If the updated side image is at index 0, also update the main image
    if (index === 0) {
      setMainImage({ file, tempUrl }); // Store both the file object and the temporary URL for the main image
    }
  }
};

const handleFormSubmit = (event) => {
  event.preventDefault();

};

const handleInputChange = (event) => {
  const value = event.target.value;

  setNewAsset({
    ...newAsset,
    [event.target.name]: value
  });

};
useEffect(() => {

}, [newAsset]);

    const handleFileChangeMobile = (event, index) => {
      if (!event.target.files[0]) return; // Exit if no file is selected

      const file = event.target.files[0];
      const tempUrl = URL.createObjectURL(file);

      // If the first image is uploaded, set it as both the main image and the first side image
      if (index === 0) {
        const imageObject = { file, tempUrl };
        setMainImage(imageObject); // Set as main image

        // Also set as the first side image
        const newSideImages = [...sideImages];
        newSideImages[0] = imageObject; // Update the first side image
        setSideImages(newSideImages);
      } else {
        // For subsequent images, just update them as side images
        const newSideImages = [...sideImages];
        newSideImages[index] = { file, tempUrl }; // Update the image at the specified index
        setSideImages(newSideImages);
      }
    };

const classes = useStyles();
const renderContent = () => {
  if (isMobile) {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: '0px',
          width: '100%',
          height: 'calc(100% - 61px)',
          flexShrink: 0,
          borderRight: '1px solid #333840',
          background: '#1A2033',
          overflow: 'scroll',
          overflowX: 'hidden',
          overflowY: 'auto',
          zIndex: '1008'
        }}
      >
        <Typography variant="body1" style={{ color: 'white', textAlign: 'left', marginTop: '20px', paddingLeft: '15px',  paddingBottom: '15px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}>
        {!displayedAssetsCount || displayedAssetsCount === 0? t('Asset Options'): displayedAssetsCount === 1 ? `${displayedAssetsCount} Asset Selected` : `${displayedAssetsCount} Assets Selected`}

        </Typography>
        <div style={{ borderTop: '1px solid gray', marginBottom: '10px', marginLeft: '10px'  }}></div>

        <div className="box">
  <div className="container-2"><span className="icon"><i className="fa fa-search"></i></span>
    <input id="search" type="search" placeholder="Search"/>
  </div>
</div>
<div style={{ height: !isExpanded ? '50px' : '220px', marginBottom: '20px', borderRadius: '15px',
        border: '1px solid #2b303b',width: '250px', marginLeft: '10px'}}>
  {!isSaveCurrentClick && (
    <Button
      onClick={handleSaveButtonClick}
      style={{
        textTransform: 'capitalize',
        color: '#FFFFFF',
        marginLeft: '10px',
        width: '250px',
        height: '50px',
        justifyContent: 'flex-start', fontFamily: 'Montserrat'
      }}
    >
      Saved Selected Assets
      <KeyboardArrowDownIcon />
    </Button>
  )}
{isExpanded && !isSaveCurrentClick && (
    <div style={{ height: isExpanded ? '50px' : '220px', marginBottom: '20px' }}>
      <Button onClick={handleSaveCurrent} style={{ textTransform: 'capitalize', color: '#FFF', fontSize: '15px', display: 'flex', justifyContent: 'left' }}>
        <AddNewSelectionIcon marginRight={'10px'} /> Save current
      </Button>
      <div  style={{ borderTop: '1px solid #333840', marginBottom: '10px', marginLeft: '10px' }}></div>
      <div className="scrollbar3" style={{overflow: 'hidden', overflowY: 'scroll', maxHeight: '120px'}}>
  {savedSelections.map((selection, index) => (
     <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
  <IconButton key={index} onClick={() => handleSelectionClick(selection)} style={{height: '10px', color: '#FFF', fontSize: '14px', marginBottom: '10px', fontFamily: 'Montserrat', justifyContent: 'left', paddingLeft: '15px', display: 'flex', flexDirection: 'column'}}>
  {selection.name}
</IconButton>
    <IconButton onClick={() => handleDeleteClick(index)} style={{ color: '#FFF' }}>
    {/* Replace with your delete icon */}
    <UncheckCircle />
  </IconButton>
  </div>
  ))}
  {savedSelections.length === 0 && (
    <ListItem>
      <Typography variant="body1" style={{ color: '#FFF', fontSize: '14px', fontFamily: 'Montserrat' }}>You don't have any saved selections yet.</Typography>
    </ListItem>
  )}
</div>
    </div>
)}
  {isSaveCurrentClick && (
    <div>
      <TextField
        value={newSelectionName}
        onChange={handleSavedSelectionNameChange}
        placeholder="Name for the selection..."
        style={{ textTransform: 'capitalize', color: '#FFFFFF', width: '100%', height: '50px', borderRadius: '15px',border: '1px solid #00D0BD',zIndex: '1008', justifyContent: 'center' }}
        variant="standard"
        InputProps={{
          style: { borderBottom: 'none', width: '250px', color: '#FFF', paddingLeft: '10px', justifyContent: 'center' }, // Remove bottom border
          disableUnderline: true // Disable default underline
        }}
      />
      <Button onClick={handleSaveNameButtonClick} variant="contained" style={{ width: '85px', textTransform: 'capitalize', background: '#1F2C40' , marginTop: '8px', position: 'relative', left: '30%'}}>
        Save
      </Button>
    </div>
  )}
</div>
<Button
              onClick={handleNewAssetClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '20px',
                marginTop: !isSaveCurrentClick ? '20px' : '60px'
              }}
            >
                   <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                  <AddNewSelectionIcon marginRight={'10px'} />  New Asset
                  </Typography>
                </Button>
                <Button
              onClick={handleDeleteAssetClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '20px',
                marginTop: !isSaveCurrentClick ? '20px' : '60px'
              }}
            >
                   <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                   <FontAwesomeIcon icon={faTrash} color="white" style={{marginRight: '10px'}} /> Delete Asset
                  </Typography>
                </Button>
                <Button
                  onClick={handleAssignAssetClick} // You need to define this function
                  style={{
                    color: "#FFFFFF",
                    flexShrink: 0,
                    maxWidth: '225px',
                    height: '40px',
                    borderRadius: '10px',
                    border: '1px solid #1F2C41',
                    background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginLeft: '20px',
                    marginTop: !isSaveCurrentClick ? '20px' : '60px'
                  }}
                >
                  <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                    <AssignmentIcon style={{marginRight: '10px'}} /> Assign Asset
                  </Typography>
                </Button>
                <>
               {isDeleteClicked &&   <Dialog
                open={deleteErrorModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogContent>
                    <Typography>
                     Please select your assets!
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                               Ok
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>}
            </>
                <Dialog
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogTitle>Delete Asset</DialogTitle>
                <DialogContent>
                    <Typography>
                      Are you sure you want to delete this asset?
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                                Cancel
                            </Button>
                            <Button onClick={handleDelete}  className={classes.button}>
                                Delete
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog
                  open={hideModalOpen}
                  onClose={handleHideModalClose}
                  PaperComponent={Paper}
                  PaperProps={{
                    style: {
                      backgroundColor: 'rgb(26, 32, 51)', // background color
                      color: '#fff', // text color
                    },
                  }}
                >
                  <DialogTitle>Hide Asset</DialogTitle>
                  <DialogContent>
                    <Typography>
                      Are you sure you want to hide this asset?
                    </Typography>
                    <DialogActions>
                      <Button onClick={handleHideModalClose} className={classes.button}>
                        Cancel
                      </Button>
                      <Button onClick={handleHide} className={classes.button}>
                        Hide
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
                <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                PaperComponent={Paper}
                maxWidth="md" // set maximum width to 'md'
                fullWidth={true} 
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <div>
      
                <DialogContent className={classes.dialogContent}>
                <DialogTitle style={{padding: '0px'}}>Add New Asset</DialogTitle>
                    <form onSubmit={handleFormSubmit}>
                    <Grid container item xs={12} sm={12} spacing={1}>
    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="name"
                            label="Name"
                            type="text"
                            fullWidth={false}
                            style={{ width: '500px' }} 
                            variant="standard"
                            value={newAsset.name}
                            onChange={handleInputChange}
                       
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                                classes: {
                                  root: classes.MuiFormLabelRoot,
                                },
                            }}
                        />
                         </Grid>
                         <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="sale-rent-label" style={{ color: '#FFF' }}>SALE/RENT</InputLabel>
                      <Select
                        labelId="sale-rent-label"
                        value={saleRentStatus}
                        onChange={(e) => setSaleRentStatus(e.target.value)}
                        name="saleRent"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat', width: '200px'}}
                      >
                      <MenuItem
                        value="FOR RENT"
                        onMouseEnter={() => setHoveredItem("FOR RENT")}
                        onMouseLeave={() => setHoveredItem(null)}
                        className={hoveredItem === "FOR RENT" ? classes.hoveredItem : null}
                      >
                        FOR RENT
                      </MenuItem>
                      <MenuItem
                        value="FOR SALE"
                        onMouseEnter={() => setHoveredItem("FOR SALE")}
                        onMouseLeave={() => setHoveredItem(null)}
                        className={hoveredItem === "FOR SALE" ? classes.hoveredItem : null}
                      >
                        FOR SALE
                      </MenuItem>
                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid container item xs={12}>
    <Grid item xs={4}>
      <TextField
        margin="dense"
        name="township"
        label="Township"
        type="text"
        width="100px"
        variant="standard"
        value={newAsset.township}
        onChange={handleInputChange}
        InputProps={{
          classes: {
            root: classes.underline,
          },
          style: {
            color: '#fff', // text color
          },
        }}
        InputLabelProps={{
          style: {
            color: '#fff', // text color
          },
        }}
      />
    </Grid>
    <Grid item xs={8}>
      <TextField
        margin="dense"
        name="address"
        label="Address"
        type="text"
        fullWidth={false}
        style={{ width: '300px' }} 
        variant="standard"
        value={newAsset.address}
        onChange={handleInputChange}
        InputProps={{
          classes: {
            root: classes.underline,
          },
          style: {
            color: '#fff', // text color
          },
        }}
        InputLabelProps={{
          style: {
            color: '#fff', // text color
          },
        }}
      />
    </Grid>
  </Grid>
                       <Grid item xs={12} >
                         <TextField
                            margin="dense"
                            name="squareFootage"
                            label="Square Footage"
                            type="text"
                            fullWidth={false}
                            variant="standard"
                            value={newAsset.squareFootage}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                            margin="dense"
                            name="price"
                            label="Price"
                            type="text"
                            fullWidth={false}
                            variant="standard"
                            value={newAsset.price}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>
                          </Grid>
                          <Grid container direction="row" spacing={3}>
                          <Grid item xs={6}>
                          <div className={classes.mobileUploadContainer}>
      {Array(10).fill().map((_, index) => (
        // Only render the slot if its index is less than or equal to the highest used index
        index <= highestUsedIndex && (
          <div key={index} className={classes.mobileUploadSlot}>
            <input
              type="file"
              id={`mobileImageUploadInput-${index}`}
              multiple
              style={{ display: 'none' }}
              onChange={(event) => handleFileChange(event, index)}
            />
            <label htmlFor={`mobileImageUploadInput-${index}`} className={classes.uploadLabel}>
              {index === 0 && mainImage?.tempUrl ? (
                <img src={mainImage.tempUrl} alt="Uploaded" style={{ width: '100%' }} />
              ) : sideImages[index]?.tempUrl ? (
                <img src={sideImages[index].tempUrl} alt="Uploaded" style={{ width: '100%' }} />
              ) : (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                <AddCircleOutlineIcon className={classes.uploadIcon} style={{ color: 'rgb(0, 208, 189)', fontSize: '20px' }}/>
                <Typography  style={{ marginLeft: '8px' }}>Upload image</Typography>
              </div>
              )}
            </label>
          </div>
        )
      ))}
    </div>
            </Grid>
            <Grid item xs={6}>
    <Grid container spacing={1}>
    <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="asset-type-label" style={{ color: '#FFF', width: '100px' }}>Asset Type</InputLabel>
                      <Select
                        labelId="asset-type-label"
                        value={newAsset.AssetType || ""}
                        onChange={handleInputChange}
                        name="AssetType"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px',  width: '200px', fontFamily: 'Montserrat'}}
                      >
                        {assetTypes && assetTypes.map((type) => (
                          <MenuItem value={type}
                            onMouseEnter={() => setHoveredItem(type)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === type ? classes.hoveredItem : null}
                          >
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="ameneties-label" style={{ color: '#FFF', width: '100px' }}>Amenities</InputLabel>
                      <Select
                        multiple
                        labelId="ameneties-label"
                        value={selectedAmeneties}
                        onChange={(e) => {
                          setSelectedAmeneties(e.target.value);
                        }}
                        renderValue={(selected) => selected.join(', ')} // , we used the join(', ') method to join the selected values with a comma and a space.
                        name="ameneties"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat', width: '100px'}}
                      >
                        {amenitiesList.map((amenity) => (
                          <MenuItem key={amenity} value={amenity}>
                            <ListItemText primary={amenity} />
                            <Checkbox
                              style={{ padding: '6px' }}
                              icon={<CircleIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
                              checkedIcon={<TripOriginIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
                              checked={checkedItems[amenity] || false} 
                              onClick={() => {
                                setCheckedItems(prevState => {
                                  return { ...prevState, [amenity]: !prevState[amenity] };
                                });
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    </Grid>
                        <Grid item xs={12}>
                           <TextField
                            margin="dense"
                            name="total_floor_count"
                            label="Total Floor Count"
                            type="text"
                            style={{width: '200px'}}
                            variant="standard"
                            value={newAsset.total_floor_count}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                            margin="dense"
                            name="floor"
                            label="Floor"
                            type="text"
                            style={{width: '200px'}}
                            variant="standard"
                            value={newAsset.floor}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}

                        />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            margin="dense"
                            name="noOfRooms"
                            label="Number of Rooms"
                            type="number"
                            style={{width: '200px'}}
                            variant="standard"
                            value={newAsset.noOfRooms}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>

                      </Grid>
                      </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                      <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="furniture-label" style={{ color: '#FFF' }}>Furniture</InputLabel>
                      <Select
                        labelId="furniture-label"
                        value={newAsset.furniture || ""}
                        onChange={handleInputChange}
                        name="furniture"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat', width: '100px'}}
                      >
                        {["Furnished", "semi furnished", "empty"].map((item) => (
                          <MenuItem value={item}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === item ? classes.hoveredItem : null}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                        <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                      <InputLabel id="condition-label" style={{ color: '#FFF', width: '200px' }}>Asset Condition</InputLabel>
                      <Select
                        labelId="condition-label"
                        value={newAsset.asset_condition || ""}
                        onChange={handleInputChange}
                        name="asset_condition"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat', width: '200px'}}
                      >
                        {["Lux", "Original state", "Renovated"].map((item) => (
                          <MenuItem value={item}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === item ? classes.hoveredItem : null}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="heating-label" style={{ color: '#FFF' }}>Heating</InputLabel>
                      <Select
                        labelId="heating-label"
                        value={newAsset.heating || ""}
                        onChange={handleInputChange}
                        name="heating"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat'}}
                      >
                        {["Central heating", "Gas", "Heat pumps", "Norwegian radiators", "EG", "TA"].map((item) => (
                          <MenuItem value={item}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === item ? classes.hoveredItem : null}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                      </Grid> 
                      <Grid container direction="column">
                      <Grid item xs={12}>
  <TextField
    name="description"
    label="Description"
    multiline
    value={newAsset.description}
    onChange={handleInputChange}
    variant="standard"
    style={{width: '100%', marginTop:'10px'}}
    InputProps={{
      classes: {
        root: classes.underline,
      },
      style: {
        color: '#fff', // text color
      },
    }}
    InputLabelProps={{
      style: {
        color: '#fff', // text color
      },
    }}
  />
</Grid>
</Grid>
                        </form>
                        <DialogActions className={classes.dialogActions}>
                          <CustomAddCancelButton onClick={handleModalClose} > Cancel </CustomAddCancelButton>
                          <CustomAddCancelButton type="submit" onClick={handleAdd} > Add </CustomAddCancelButton>
                        </DialogActions>
                   
                </DialogContent>
                </div>
            </Dialog>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'  }}>
        {/* Proposal Button */}
        <Button
  variant="outlined"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '50px',
    marginBottom: '10px',
    marginTop: '40px',
    marginLeft: '10px',
    color: '#FFFFFF',
    borderRadius: '15px',
    border: '1px solid #2b303b',
    textTransform: 'none',
    fontFamily: 'Montserrat',
    borderColor: '#2b303b',
    boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
    transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
  }}
  onMouseOver={(e) => {
    handleEnvelopeHover();
    e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.5)'; // Adjusted box shadow on hover
  }}
  onMouseOut={(e) => {
    handleEnvelopeMouseOut();
    e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
  }}
  className="my-button"
>
  <Envelope fill={isEnvelopeHovered ? 'rgba(0, 208, 189)' : '#FFFFFF'} />
  <Typography className="my-button" style={{ marginLeft: '10px', fontFamily: 'Montserrat' }}>
    {t('Send Proposal')}
  </Typography>
</Button>
        {/* Invoice Button */}
        <Button
          variant="outlined"
          style={{ 
            width: '90%',
            height: '50px',
            marginBottom: '10px',
            marginLeft: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            borderRadius: '15px',
            textTransform: 'none',
            fontFamily: 'Montserrat',
            border: '1px solid #2b303b',
            borderColor: '#2b303b',
            boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
            transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
          }}
          onMouseOver={(e) => {
            handleDollarHover();
            e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.5)'; // Adjusted box shadow on hover
          }}
          onMouseOut={(e) => {
            handleDollarMouseOut();
            e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          }}
          onClick={sendSelectedAssetsToBackend}
          className="my-button"
        >
          <CurrencyCircleDollar fill={isDollarHovered ? 'rgba(0, 208, 189)' : '#FFFFFF'} />
          <Typography className="my-button" style={{ marginLeft: '10px', fontFamily: 'Montserrat' }}>{t('Send Invoice')}</Typography>
        </Button>


        {/* Print Button */}
        <Button
          variant="outlined"
          style={{ 
            width: '90%',
            height: '50px',
            marginBottom: '10px',
            marginLeft: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            borderRadius: '15px',
            textTransform: 'none',
            fontFamily: 'Montserrat',
            border: '1px solid #2b303b',
            borderColor: '#2b303b',
            boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
            transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
          }}
          onMouseOver={(e) => {
            handlePdfHover();
            e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.5)'; // Adjusted box shadow on hover
          }}
          onMouseOut={(e) => {
            handlePdfMouseOut();
            e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          }}
          onClick={exportSelectedAssets}
          className="my-button"
        >
          <FilePdf fill={isPdfHovered ? 'rgba(0, 208, 189)' : '#FFFFFF'} />
          <Typography className="my-button" style={{ marginLeft: '10px' }}>{t('Export PDF')}</Typography>
        </Button>
        </div>
      </div>
    </ThemeProvider>
  );
} else {

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: '60px',
          width: '250px',
          height: '100vh',
          flexShrink: 0,
          borderRight: '1px solid #333840',
          background: '#1A2033',
          overflow: 'scroll',
          overflowX: 'hidden',
          overflowY: 'auto',
          zIndex: '1008'
        }}
      >
        <Typography variant="body1" style={{ color: 'white', textAlign: 'left', marginTop: '20px', paddingLeft: '15px',  paddingBottom: '15px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}>
        {!displayedAssetsCount || displayedAssetsCount === 0? t('Asset Options'): displayedAssetsCount === 1 ? `${displayedAssetsCount} Asset Selected` : `${displayedAssetsCount} Assets Selected`}
        </Typography>
        <div style={{ borderTop: '1px solid gray', marginBottom: '10px', marginLeft: '10px'  }}></div>

        <div className="box">
  <div className="container-2"><span className="icon"><i className="fa fa-search"></i></span>
    <input id="search" type="search" placeholder="Search"/>
  </div>
</div>
<div style={{ height: !isExpanded ? '50px' : '220px', marginBottom: '20px', borderRadius: '15px',
        border: '1px solid #2b303b',width: '90%', marginLeft: '10px'}}>
  {!isSaveCurrentClick && (
    <Button
      onClick={handleSaveButtonClick}
      style={{
        textTransform: 'capitalize',
        color: '#FFFFFF',
        marginLeft: '10px',
        width: '250px',
        height: '50px',
        justifyContent: 'flex-start', fontFamily: 'Montserrat'
      }}
    >
      Saved Selected Assets
      <KeyboardArrowDownIcon />
    </Button>
  )}
{isExpanded && !isSaveCurrentClick && (
    <div style={{ height: isExpanded ? '50px' : '220px', marginBottom: '20px' }}>
      <Button onClick={handleSaveCurrent} style={{ textTransform: 'capitalize', color: '#FFF', fontSize: '15px', display: 'flex', justifyContent: 'left' }}>
        <AddNewSelectionIcon marginRight={'10px'} /> Save current
      </Button>
      <div  style={{ borderTop: '1px solid #333840', marginBottom: '10px', marginLeft: '10px' }}></div>
      <div className="scrollbar3" style={{overflow: 'hidden', overflowY: 'scroll', maxHeight: '120px'}}>
  {savedSelections.map((selection, index) => (
     <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
  <IconButton key={index} onClick={() => handleSelectionClick(selection)} style={{height: '10px', color: '#FFF', fontSize: '14px', marginBottom: '10px', fontFamily: 'Montserrat', justifyContent: 'left', paddingLeft: '15px', display: 'flex', flexDirection: 'column'}}>
  {selection.name}
</IconButton>
    <IconButton onClick={() => handleDeleteClick(index)} style={{ color: '#FFF' }}>
    {/* Replace with your delete icon */}
    <UncheckCircle />
  </IconButton>
  </div>
  ))}
  {savedSelections.length === 0 && (
    <ListItem>
      <Typography variant="body1" style={{ color: '#FFF', fontSize: '14px', fontFamily: 'Montserrat' }}>You don't have any saved selections yet.</Typography>
    </ListItem>
  )}
</div>
    </div>
)}
  {isSaveCurrentClick && (
    <div>
      <TextField
        value={newSelectionName}
        onChange={handleSavedSelectionNameChange}
        placeholder="Name for the selection..."
        style={{ textTransform: 'capitalize', color: '#FFFFFF', width: '100%', height: '50px', borderRadius: '15px',border: '1px solid #00D0BD',zIndex: '1008', justifyContent: 'center' }}
        variant="standard"
        InputProps={{
          style: { borderBottom: 'none', width: '250px', color: '#FFF', paddingLeft: '10px', justifyContent: 'center' }, // Remove bottom border
          disableUnderline: true // Disable default underline
        }}
      />
      <Button onClick={handleSaveNameButtonClick} variant="contained" style={{ width: '85px', textTransform: 'capitalize', background: '#1F2C40' , marginTop: '8px', position: 'relative', left: '30%'}}>
        Save
      </Button>
    </div>
  )}
</div>
<Button
              onClick={handleNewAssetClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '20px',
                marginTop: !isSaveCurrentClick ? '20px' : '60px'
              }}
            >
                   <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                  <AddNewSelectionIcon marginRight={'10px'} />  New Asset
                  </Typography>
                </Button>
                {(permissions || []).includes('can_hide_data') && (

  <Button
    onClick={handleHideAssetClick} // Change this to your hide asset function
    style={{
      color: "#FFFFFF",
      flexShrink: 0,
      maxWidth: '225px',
      height: '40px',
      borderRadius: '10px',
      border: '1px solid #1F2C41',
      background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginLeft: '20px',
      marginTop: !isSaveCurrentClick ? '20px' : '60px'
    }}
  >
    <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
      <FontAwesomeIcon icon={faTrash} color="white" style={{marginRight: '10px'}} /> Hide Asset
    </Typography>
  </Button>
)}
{(permissions || []).includes('can_delete_data') && (
                <Button
              onClick={handleDeleteAssetClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '20px',
                marginTop: !isSaveCurrentClick ? '20px' : '60px'
              }}
            >
                   <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                   <FontAwesomeIcon icon={faTrash} color="white" style={{marginRight: '10px'}} /> Delete Asset
                  </Typography>
                </Button>
                )}
                <Button
                  onClick={handleAssignAssetClick} // You need to define this function
                  style={{
                    color: "#FFFFFF",
                    flexShrink: 0,
                    maxWidth: '225px',
                    height: '40px',
                    borderRadius: '10px',
                    border: '1px solid #1F2C41',
                    background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginLeft: '20px',
                    marginTop: !isSaveCurrentClick ? '20px' : '60px'
                  }}
                >
                  <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                    <AssignmentIcon style={{marginRight: '10px'}} /> Assign Asset
                  </Typography>
                </Button>
                <>
                <Dialog
               id="assign-dialog" // Add this line   
                open={assignModalOpen}
                onClose={() => setAssignModalOpen(false)}
                PaperComponent={Paper}
                maxWidth="md" // Add this line to make the dialog wider
                PaperProps={{
                  style: {
                    backgroundColor: 'rgb(26, 32, 51)', // background color
                    color: '#fff', // text color
                  },
                }}
              >
  <DialogTitle>Assigning to:</DialogTitle>
  <DialogContent>
    <FormControl style={{ minWidth: 200 }}> {/* Add this line to make the select wider */}
      <InputLabel id="assignee-label" style={{ color: '#fff', fontSize: '18px', fontFamily: 'Montserrat' }}>Assignee</InputLabel> {/* Add the style property to change the color */}
      <Select
       className={classes.select}
       classes={{ icon: classes.icon }}
    labelId="assignee-label"
    id="assignee-select"
    value={assignee}
    onChange={handleAssigneeChange}
    InputProps={{
      style: {
        color: '#fff',
      },
    }}
    MenuProps={{
      PaperProps: {
        style: {
          backgroundColor: 'rgb(26, 32, 51)', // Change the background color of the dropdown
          maxHeight: '300px', 
          overflow: 'auto',
          overflowX: 'hidden',
        },
      },
      classes: { paper: 'assign-dialog-menu' }, // Add this line
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null, // This line is important to make the dropdown open below the select field
    }}
  >

{users.map((user) => (
  <MenuItem  key={user.id} value={user.id} style={{ color: '#fff' }}>
    {user.email}
  </MenuItem>
))}
  </Select>
    </FormControl>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center'}}>
    <IconButton
                           onClick={() => setAssignModalOpen(false)} className={classes.button}
                            style={{
                              textTransform: 'capitalize',
                              color: '#FFF',
                              backgroundColor: '#0E1529', // Set the background color
                              fontFamily: 'Montserrat',
                              boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
                              transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out', // Smooth transition effect for box shadow and text color
                              fontSize: '15px',
                              borderRadius: '10px',
                              marginRight: '10px',
                      
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)'; // Adjusted box shadow on hover
                              e.currentTarget.style.color = 'rgb(0, 208, 189)';// Change text color on hover
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
                              e.currentTarget.style.color = 'white'; // Restore text color on mouse out
                            }}
                          >
                            Cancel
                          </IconButton>

    <IconButton
                            type="submit"
                            style={{
                              textTransform: 'capitalize',
                              color: '#FFF',
                              backgroundColor: '#0E1529', // Set the background color
                              fontFamily: 'Montserrat',
                              boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
                              transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out', // Smooth transition effect for box shadow and text color
                              fontSize: '15px',
                              borderRadius: '10px'
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)'; // Adjusted box shadow on hover
                              e.currentTarget.style.color = 'rgb(0, 208, 189)';// Change text color on hover
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
                              e.currentTarget.style.color = 'white'; // Restore text color on mouse out
                            }} 
                            onClick={() => handleAssign(assignee, selectedAssets)} className={classes.button}
                          >
                            Assign
                          </IconButton>
  </DialogActions>
</Dialog>
                </>
                <>
               {isDeleteClicked &&   <Dialog
                open={deleteErrorModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogContent>
                    <Typography>
                     Please select your assets!
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                               Ok
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>}
            </>
                <Dialog
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogTitle>Delete Asset</DialogTitle>
                <DialogContent>
                    <Typography>
                      Are you sure you want to delete this asset?
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                                Cancel
                            </Button>
                            <Button onClick={handleDelete}  className={classes.button}>
                                Delete
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog
                  open={hideModalOpen}
                  onClose={handleHideModalClose}
                  PaperComponent={Paper}
                  PaperProps={{
                    style: {
                      backgroundColor: 'rgb(26, 32, 51)', // background color
                      color: '#fff', // text color
                    },
                  }}
                >
                  <DialogTitle>Hide Asset</DialogTitle>
                  <DialogContent>
                    <Typography>
                      Are you sure you want to hide this asset?
                    </Typography>
                    <DialogActions>
                      <Button onClick={handleHideModalClose} className={classes.button}>
                        Cancel
                      </Button>
                      <Button onClick={handleHide} className={classes.button}>
                        Hide
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
                <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                PaperComponent={Paper}
                maxWidth="md" // set maximum width to 'md'
                fullWidth={true} 
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <div>
      
                <DialogContent className={classes.dialogContent}>
                <DialogTitle style={{padding: '0px'}}>Add New Asset</DialogTitle>
                    <form onSubmit={handleFormSubmit}>
                    <Grid container item xs={12} sm={12} spacing={1}>
                  
  <Grid container spacing={4} alignItems="center">
    <Grid item xs={6}>
      <TextField
        margin="dense"
        name="name"
        label="Name"
        type="text"
        fullWidth={false}
        style={{ width: '100%' }} // Adjusted to fill the grid item
        variant="standard"
        value={newAsset.name}
        onChange={handleInputChange}
        InputProps={{
          classes: {
            root: classes.underline,
          },
          style: {
            color: '#fff', // text color
          },
        }}
        InputLabelProps={{
          style: {
            color: '#fff', // text color
          },
          classes: {
            root: classes.MuiFormLabelRoot,
          },
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <DatePicker
        className="transparent-input"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
      />
    </Grid>
    <Grid item xs={3}>
      <DatePicker
        className="transparent-input"
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="End Date"
      />
    </Grid>
  </Grid>

                         <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="sale-rent-label" style={{ color: '#FFF' }}>SALE/RENT</InputLabel>
                      <Select
                        labelId="sale-rent-label"
                        value={saleRentStatus}
                        onChange={(e) => setSaleRentStatus(e.target.value)}
                        name="saleRent"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat', width: '200px'}}
                      >
                      <MenuItem
                        value="FOR RENT"
                        onMouseEnter={() => setHoveredItem("FOR RENT")}
                        onMouseLeave={() => setHoveredItem(null)}
                        className={hoveredItem === "FOR RENT" ? classes.hoveredItem : null}
                      >
                        FOR RENT
                      </MenuItem>
                      <MenuItem
                        value="FOR SALE"
                        onMouseEnter={() => setHoveredItem("FOR SALE")}
                        onMouseLeave={() => setHoveredItem(null)}
                        className={hoveredItem === "FOR SALE" ? classes.hoveredItem : null}
                      >
                        FOR SALE
                      </MenuItem>
                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid container item xs={12}>
    <Grid item xs={4}>
      <TextField
        margin="dense"
        name="township"
        label="Township"
        type="text"
        width="100px"
        variant="standard"
        value={newAsset.township}
        onChange={handleInputChange}
        InputProps={{
          classes: {
            root: classes.underline,
          },
          style: {
            color: '#fff', // text color
          },
        }}
        InputLabelProps={{
          style: {
            color: '#fff', // text color
          },
        }}
      />
    </Grid>
    <Grid item xs={8}>
      <TextField
        margin="dense"
        name="address"
        label="Address"
        type="text"
        fullWidth={false}
        style={{ width: '300px' }} 
        variant="standard"
        value={newAsset.address}
        onChange={handleInputChange}
        InputProps={{
          classes: {
            root: classes.underline,
          },
          style: {
            color: '#fff', // text color
          },
        }}
        InputLabelProps={{
          style: {
            color: '#fff', // text color
          },
        }}
      />
    </Grid>
  </Grid>
                       <Grid item xs={12} >
                         <TextField
                            margin="dense"
                            name="squareFootage"
                            label="Square Footage"
                            type="text"
                            fullWidth={false}
                            variant="standard"
                            value={newAsset.squareFootage}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                            margin="dense"
                            name="price"
                            label="Price"
                            type="text"
                            fullWidth={false}
                            variant="standard"
                            value={newAsset.price}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>
                          </Grid>
                          <Grid container direction="row" spacing={3}>
                          <Grid item xs={6}>
                    <div className={classes.imageContainer}>
                    
                    <div className={classes.imageUpload} onClick={() => handleImageUploadClick('main')}>
              {!mainImage && ( // Only show the icon and text if mainImage is not set
                <>
                  <AddCircleOutlineIcon className={classes.uploadIcon} style={{color: 'rgb(0, 208, 189)'}}/>
                  <Typography variant="h6">Add Image</Typography>
                </>
              )}
              {mainImage && <img src={mainImage.tempUrl} alt=""  style={{ width: '345px', height: '335px', objectFit: 'cover' }} />}
              <input
                type="file"
                id="mainImageUploadInput"
                style={{ display: 'none' }}
                onChange={handleFileChangeMain}
              />
            </div>
            <div className="sideImages scrollbar6">
  {Array(10).fill().map((_, index) => (
    <div key={index} className={classes.sideImage} onClick={() => document.getElementById(`sideImageUploadInput-${index}`).click()}>
      {sideImages[index] ? (
        <img src={sideImages[index].tempUrl} alt={`Side ${index + 1}`} style={{ width: '80px', height: '60px', objectFit: 'cover' }} />
      ) : (
        <AddCircleOutlineIcon className={classes.uploadIcon} style={{color: 'rgb(0, 208, 189)', fontSize: '22px'}}/>
      )}
      <input
        type="file"
        id={`sideImageUploadInput-${index}`}
        style={{ display: 'none' }}
        onChange={(event) => handleFileChangeSide(event, index)}
      />
    </div>
  ))}
</div>
            </div>
            </Grid>
            <Grid item xs={6}>
    <Grid container spacing={1}>
    <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="asset-type-label" style={{ color: '#FFF' }}>Asset Type</InputLabel>
                      <Select
                        labelId="asset-type-label"
                        value={newAsset.AssetType || ""}
                        onChange={handleInputChange}
                        name="AssetType"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px',  width: '200px', fontFamily: 'Montserrat'}}
                      >
    {assetTypes && assetTypes.map((type) => (
      <MenuItem key={type} value={type}
        onMouseEnter={() => setHoveredItem(type)}
        onMouseLeave={() => setHoveredItem(null)}
        className={hoveredItem === type ? classes.hoveredItem : null}
      >
        {type}
      </MenuItem>
    ))}
                      </Select>
                    </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="ameneties-label" style={{ color: '#FFF' }}>Amenities</InputLabel>
                      <Select
                        multiple
                        labelId="ameneties-label"
                        value={selectedAmeneties}
                        onChange={(e) => {
                          setSelectedAmeneties(e.target.value);
                        }}
                        renderValue={(selected) => selected.join(', ')} // , we used the join(', ') method to join the selected values with a comma and a space.
                        name="ameneties"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat'}}
                      >
                        {amenitiesList.map((amenity) => (
                          <MenuItem key={amenity} value={amenity}>
                            <ListItemText primary={amenity} />
                            <Checkbox
                              style={{ padding: '6px' }}
                              icon={<CircleIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
                              checkedIcon={<TripOriginIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
                              checked={checkedItems[amenity] || false} 
                              onClick={() => {
                                setCheckedItems(prevState => {
                                  return { ...prevState, [amenity]: !prevState[amenity] };
                                });
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    </Grid>
                        <Grid item xs={12}>
                           <TextField
                            margin="dense"
                            name="total_floor_count"
                            label="Total Floor Count"
                            type="text"
                            style={{width: '200px'}}
                            variant="standard"
                            value={newAsset.total_floor_count}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                            margin="dense"
                            name="floor"
                            label="Floor"
                            type="text"
                            style={{width: '200px'}}
                            variant="standard"
                            value={newAsset.floor}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}

                        />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            margin="dense"
                            name="noOfRooms"
                            label="Number of Rooms"
                            type="number"
                            style={{width: '200px'}}
                            variant="standard"
                            value={newAsset.noOfRooms}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    root: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        </Grid>

                      </Grid>
                      </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                      <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="furniture-label" style={{ color: '#FFF' }}>Furniture</InputLabel>
                      <Select
                        labelId="furniture-label"
                        value={newAsset.furniture || ""}
                        onChange={handleInputChange}
                        name="furniture"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat'}}
                      >
                        {["Furnished", "semi furnished", "empty"].map((item) => (
                          <MenuItem  key={item} value={item}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === item ? classes.hoveredItem : null}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                        <Grid item xs={4}>
                      <FormControl className={classes.formControl}>
                      <InputLabel id="condition-label" style={{ color: '#FFF' }}>Asset Condition</InputLabel>
                      <Select
                        labelId="condition-label"
                        value={newAsset.asset_condition || ""}
                        onChange={handleInputChange}
                        name="asset_condition"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat'}}
                      >
                        {["Lux", "Original state", "Renovated"].map((item) => (
                          <MenuItem  key={item} value={item}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === item ? classes.hoveredItem : null}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="heating-label" style={{ color: '#FFF' }}>Heating</InputLabel>
                      <Select
                        labelId="heating-label"
                        value={newAsset.heating || ""}
                        onChange={handleInputChange}
                        name="heating"
                        className={classes.select}
                        classes={{ icon: classes.icon }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: classes.paper,
                            list: classes.item,
                          },
                        }}
                        inputProps={{
                          classes: {
                            root: classes.underline,
                          },
                        }}
                        style={{color: '#FFF', fontSize: '16px', fontFamily: 'Montserrat'}}
                      >
                        {["Central heating", "Gas", "Heat pumps", "Norwegian radiators", "EG", "TA"].map((item) => (
                          <MenuItem  key={item} value={item}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(null)}
                            className={hoveredItem === item ? classes.hoveredItem : null}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      </Grid>
                      </Grid> 
                      <Grid container direction="column">
                      <Grid item xs={12}>
  <TextField
    name="description"
    label="Description"
    multiline
    value={newAsset.description}
    onChange={handleInputChange}
    variant="standard"
    style={{width: '100%', marginTop:'10px'}}
    InputProps={{
      classes: {
        root: classes.underline,
      },
      style: {
        color: '#fff', // text color
      },
    }}
    InputLabelProps={{
      style: {
        color: '#fff', // text color
      },
    }}
  />
</Grid>
</Grid>
                        </form>
                        <DialogActions className={classes.dialogActions}>
                          <CustomAddCancelButton onClick={handleModalClose} > Cancel </CustomAddCancelButton>
                          <CustomAddCancelButton type="submit" onClick={handleAdd} > Add </CustomAddCancelButton>
                        </DialogActions>
                   
                </DialogContent>
                </div>
            </Dialog>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'  }}>
        {/* Proposal Button */}
        <Button
  variant="outlined"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '50px',
    marginBottom: '10px',
    marginTop: '40px',
    marginLeft: '10px',
    color: '#FFFFFF',
    borderRadius: '15px',
    border: '1px solid #2b303b',
    textTransform: 'none',
    fontFamily: 'Montserrat',
    borderColor: '#2b303b',
    boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
    transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
  }}
  onMouseOver={(e) => {
    handleEnvelopeHover();
    e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.5)'; // Adjusted box shadow on hover
  }}
  onMouseOut={(e) => {
    handleEnvelopeMouseOut();
    e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
  }}
  className="my-button"
>
  <Envelope fill={isEnvelopeHovered ? 'rgba(0, 208, 189)' : '#FFFFFF'} />
  <Typography className="my-button" style={{ marginLeft: '10px' , fontFamily: 'Montserrat'}}>
    {t('Send Proposal')}
  </Typography>
</Button>
        {/* Invoice Button */}
        <Button
          variant="outlined"
          style={{ 
            width: '90%',
            height: '50px',
            marginBottom: '10px',
            marginLeft: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            borderRadius: '15px',
            textTransform: 'none',
            fontFamily: 'Montserrat',
            border: '1px solid #2b303b',
            borderColor: '#2b303b',
            boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
            transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
          }}
          onMouseOver={(e) => {
            handleDollarHover();
            e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.5)'; // Adjusted box shadow on hover
          }}
          onMouseOut={(e) => {
            handleDollarMouseOut();
            e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          }}
          onClick={sendSelectedAssetsToBackend}
          className="my-button"
        >
          <CurrencyCircleDollar fill={isDollarHovered ? 'rgba(0, 208, 189)' : '#FFFFFF'} />
          <Typography className="my-button" style={{ marginLeft: '10px', fontFamily: 'Montserrat' }}>{t('Send Invoice')}</Typography>
        </Button>


        {/* Print Button */}
        <Button
          variant="outlined"
          style={{ 
            width: '90%',
            height: '50px',
            marginBottom: '10px',
            marginLeft: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            borderRadius: '15px',
            textTransform: 'none',
            fontFamily: 'Montserrat',
            border: '1px solid #2b303b',
            borderColor: '#2b303b',
            boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
            transition: 'box-shadow 0.3s ease-in-out', // Smooth transition effect
          }}
          onMouseOver={(e) => {
            handlePdfHover();
            e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.5)'; // Adjusted box shadow on hover
          }}
          onMouseOut={(e) => {
            handlePdfMouseOut();
            e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          }}
          onClick={exportSelectedAssets}
          className="my-button"
        >
          <FilePdf fill={isPdfHovered ? 'rgba(0, 208, 189)' : '#FFFFFF'} />
          <Typography className="my-button" style={{ marginLeft: '10px', fontFamily: 'Montserrat' }}>{t('Export PDF')}</Typography>
        </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}
};
return (
  <React.Fragment>
    {renderContent()}
  </React.Fragment>
)
};
export default Settings;
