  // FilterComponent.js
  import { Checkbox, FormControlLabel, IconButton, InputAdornment, InputBase, RadioGroup, Typography } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import RangeSlider from './PriceRange';
  import CalendarComponent from './CalendarComponent';
  import '../styles/FilterComponent.css';
  import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import Button from '@mui/material/Button';
  import Popover from '@mui/material/Popover';
  import List from '@mui/material/List';
  import ListItem from '@mui/material/ListItem';
  import CircleIcon from '@mui/icons-material/Circle';
  import TripOriginIcon from '@mui/icons-material/TripOrigin';
  // This function creates a FormControlLabel component with a custom label and Checkbox control.
  // The 'value', 'label', and 'onChange' props are passed as arguments to the function.
  // The 'label' prop is a Typography component with custom styles.
  // The 'control' prop is a Checkbox component with custom styles and icons.
  // The 'onChange' prop is a function that is called when the Checkbox is checked or unchecked.
  import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
  import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
  import AddIcon from '@mui/icons-material/Add';
  import AddFiltersModal from './AddFiltersModal';
  import EditIcon from '../assets/PencilSimple';
  import {useTranslation} from 'react-i18next';
  import i18n, { use } from 'i18next';
  import MobileCalendar from './MobileCalendar';
  import FadersHorizontal from '../assets/FadersHorizontal';
    const MAX_ITEMS_TO_SHOW = 4; // Set the maximum number of items to show initially
    const ITEM_HEIGHT = 'auto'; // Adjust this based on your design

  // This is the main FilterComponent.
  // It uses React's useState hook to manage several pieces of state: 'activeButton', 'numberOfRooms', 'priceRange', 'assetTypeFilter', 'selectedAmenity', and 'filteredAmenities'.
  // It also defines several event handlers: 'handleButtonClick', 'handleNumberOfRoomsChange', 'handlePriceRangeChange', 'handleAssetTypeChange', and 'handleAmenityChange'.
  // These event handlers update the corresponding pieces of state and call the corresponding props functions to notify the parent component of the changes.
  const FilterComponent = (props ) => {

    const createFormControlLabel = (value, label, onChange) => (
      <FormControlLabel
        key={value}
        value={value}
        label={(
          <Typography
            variant="h6"
            noWrap
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: '#FFF',
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: 'normal',
              flexShrink: 0,
              marginLeft: '3px',
            }}
          >
            {label}
          </Typography>
        )}
        labelPlacement="start"
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          marginLeft: '3px',
        }}
        control={(
          <Checkbox
            style={{ padding: '6px' }}
            icon={<CircleIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
            checkedIcon={<TripOriginIcon fontSize='small' sx={{ color: '#FFFFFF', width: '17px', height: '17px' }} />}
            checked={!!checkedItems[value]}
            onChange={() => {
              onChange(value);
              setCheckedItems(prevState => ({ ...prevState, [value]: !prevState[value] }));
            }}
          />
        )}
      />
    );
    
      const [activeButton, setActiveButton] = useState('list');
      const [isToggleOn, setIsToggleOn] = useState(false);
      const [lastButtonClicked, setLastButtonClicked] = useState('');

      const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        setIsDraggingEnabled(buttonName === 'edit');
        setIsEditFiltersActive(buttonName === 'edit');
        setAssetTypeWidth(buttonName === 'edit' ? '80%' : '100%');
      
        if (buttonName === 'grid') {
          setIsToggleOn(false);
          setShowAvailabilityFilter(false); // Do not show the "Availability" filter for "Buy"
          setLastButtonClicked('grid');
        } else if (buttonName === 'list') {
          setIsToggleOn(false);
          setShowAvailabilityFilter(true); // Show the "Availability" filter for "Rent"
          setLastButtonClicked('list');
        } else if (buttonName === 'edit') {
          // When "Edit" is clicked, decide based on the last non-"Edit" button clicked
          if (lastButtonClicked === 'grid') {
            setShowAvailabilityFilter(false);
          } else if (lastButtonClicked === 'list') {
            setShowAvailabilityFilter(true);
          }
          // No need to update lastButtonClicked here
        } else {
          // For any other button, you might want to reset or handle differently
          setShowAvailabilityFilter(true); // Default behavior or implement as needed
        }
      };
        const { onDataFiltered, onPriceFiltered , onAssetTypeFiltered , onAmenitiesFiltered, onFurnitureFiltered, onSaleRentFiltered, onAssetConditionFiltered, onAvailabilityFiltered, onHeatingFiltered, onStatusFiltered,  onHiddenAssetsFiltered, onLanguageFiltered, numberOfRooms, setNumberOfRooms, priceRange, setPriceRange, assetTypes, selectedAssetType, setSelectedAssetType, checkedItems, setCheckedItems, userId, filterOrder, setFilterOrder,   onClose, hiddenAssetsCount , state, setState} = props;

        const [showAllAmenities, setShowAllAmenities] = useState(false);
        const [showAllLanguages, setShowAllLanguages] = useState(false);

        const [leftPosition, setLeftPosition] = useState('60px'); // Default left position

        useEffect(() => {
          // Update left position based on screen width
          const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 550) {
              setLeftPosition('50px');
            } else {
              setLeftPosition('60px');
            }
          };
      
          // Initial call and listen for window resize
          handleResize();
          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);

        const { t } = useTranslation();
        
        const [amenitiesList, setAmenitiesList] = useState([]);
        const [furnitureList, setFurnitureList] = useState([]);
        const [assetConditionList, setAssetConditionList] = useState([]);
        const [heatingList, setHeatingList] = useState([]);
        const [statusList, setStatusList] = useState([]);
        const [showAvailabilityFilter, setShowAvailabilityFilter] = useState(true); // New state variable
        const [saleRentOptions, setSaleRentOptions] = useState([]);

        const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
        useEffect(() => {
          // Fetch amenities data from the backend API
          fetch(`${API_URL}/api/filter/amenities`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to fetch amenities data');
              }
              return response.json();
            })
            .then(data => {
              // Set the fetched amenities data in the state
              setAmenitiesList(data.amenities);
            })
            .catch(error => {
              console.error('Error fetching amenities data:', error);
            });
        
          // Fetch furniture data from the backend API
          fetch(`${API_URL}/api/filter/furniture`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to fetch furniture data');
              }
              return response.json();
            })
            .then(data => {
              // Set the fetched furniture data in the state
              setFurnitureList(data.furniture);
            })
            .catch(error => {
              console.error('Error fetching furniture data:', error);
            });
                      // Fetch furniture data from the backend API
          fetch(`${API_URL}/api/filter/assetCondition`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch furniture data');
            }
            return response.json();
          })
          .then(data => {
            // Set the fetched furniture data in the state
            setAssetConditionList(data.asset_condition);
          })
          .catch(error => {
            console.error('Error fetching furniture data:', error);
          });
          fetch(`${API_URL}/api/filter/heating`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch heating data');
            }
            return response.json();
          })
          .then(data => {
            // Set the fetched furniture data in the state
            setHeatingList(data.heating);
          })
          .catch(error => {
            console.error('Error fetching heating data:', error);
          });
          fetch(`${API_URL}/api/filter/status`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch status data');
            }
            return response.json();
          })
          .then(data => {
            // Set the fetched status data in the state
            setStatusList(data.status);
          });

            fetch(`${API_URL}/api/filter/saleRent`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Failed to fetch Sale/Rent data');
              }
              return response.json();
            })
            .then(data => {
             // Assuming the backend returns an array of assets with sale and rent statuses
      // Transform the data to be suitable for the RadioGroup component
      const options = data.assets.map((asset) => {
        return asset.sale === 'For Sale' ? 'sale' : 'rent';
      });
      // Remove duplicates
      const uniqueOptions = Array.from(new Set(options));
      setSaleRentOptions(uniqueOptions); // Assuming you have a state setter for saleRentOptions
    })
            .catch(error => {
              console.error('Error fetching Sale/Rent data:', error);
            });
        }, []);
        
        const languagesList = [
          t("Spanish"),
          t("English"),
          t("French"),
          t("Italian"),
          t("German"),
         
        ];

        const handleShowAllAmenities = () => {
          setShowAllAmenities(!showAllAmenities);
        };
      
        const handleShowAllLanguages = () => {
          setShowAllLanguages(!showAllLanguages);
        };
      
        
        const renderAmenities = () => {
          // Ensure amenitiesList is defined before calling map
          const itemsToRender = showAllAmenities ? amenitiesList : amenitiesList.slice(0, MAX_ITEMS_TO_SHOW);
          
          return itemsToRender.map((amenity, index) => {
            return createFormControlLabel(
              amenity,
              <Typography

                noWrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#FFF',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  flexShrink: 0,
                  marginLeft: '3px',
                }}
              >
                {t(amenity)}
              </Typography>,
              () => {
                onAmenitiesFiltered(amenity);
              }
            );
          });
        };
        
        const renderFurniture = () => {
          // Ensure furnitureList is defined before calling map
          if (!furnitureList) return null;
        
          return furnitureList.map((furniture, index) => {
            return createFormControlLabel(
              furniture,
              <Typography
                noWrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#FFF',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  flexShrink: 0,
                  marginLeft: '3px',
                  textTransform: 'capitalize',
                }}
              >
                {t(furniture)}
              </Typography>,
              () => {
                onFurnitureFiltered(furniture);
              }
            );
          });
        };

        const renderSaleRentOptions = () => {
          if (!saleRentOptions) return null;
        
          return saleRentOptions.map((option, index) => {
            return createFormControlLabel(
              option,
              <Typography
                noWrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#FFF',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  flexShrink: 0,
                  marginLeft: '3px',
                  textTransform: 'capitalize',
                }}
              >
                {t(option)}
              </Typography>,
              () => {
                onSaleRentFiltered(option);
              }
            );
          });
        };

        const renderAssetCondition = () => {
          // Ensure assetConditionList is defined before calling map
          if (!assetConditionList) return null;
        
          return assetConditionList.map((asset_condition, index) => {
            return createFormControlLabel(
              asset_condition,
              <Typography
                noWrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#FFF',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  flexShrink: 0,
                  marginLeft: '3px',
                  textTransform: 'capitalize',
                }}
              >
                {t(asset_condition)}
              </Typography>,
              () => {
                onAssetConditionFiltered(asset_condition);
              }
            );
          });
        };

        const renderHeating = () => {
          // Ensure heatingList is defined before calling map
          if (!heatingList) return null;
        
          return heatingList.map((heating, index) => {
            return createFormControlLabel(
              heating,
              <Typography
                noWrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#FFF',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  flexShrink: 0,
                  marginLeft: '3px',
                  textTransform: 'capitalize',
                }}
              >
                {t(heating)}
              </Typography>,
              () => {
                onHeatingFiltered(heating);
              }
            );
          });
        };

        const renderStatus = () => {
          // Ensure statusList is defined before calling map
          if (!statusList) return null;
        
          return statusList.map((status, index) => {
            return createFormControlLabel(
              status,
              <Typography
                noWrap
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#FFF',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  flexShrink: 0,
                  marginLeft: '3px',
                  textTransform: 'capitalize',
                }}
              >
                {t(status)}
              </Typography>,
              () => {
                onStatusFiltered(status);
              }
            );
          });
        };

        const renderHiddenAssets = () => {
          // Assuming hiddenAssetsCount is the total number of hidden assets
          return createFormControlLabel(
            'Show Hidden Assets',
            <Typography
              noWrap
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#FFF',
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
                flexShrink: 0,
                marginLeft: '3px',
                textTransform: 'capitalize',
              }}
            >
                  {t("Show Hidden Assets ({{count}})", { count: hiddenAssetsCount })}
            </Typography>,
            () => {
              onHiddenAssetsFiltered();
            }
          );
        };

        const renderLanguages = () => {
          const itemsToRender = showAllLanguages
            ? languagesList
            : languagesList.slice(0, MAX_ITEMS_TO_SHOW);
        
          return itemsToRender.map((language) => {
            const englishLanguage = i18n.getFixedT('en-US')(language);
            return createFormControlLabel(language, englishLanguage, () => {
              onLanguageFiltered(englishLanguage);
            });
          });
        };
      
        const getDivHeight = (showAll, itemCount) => {
          const height = showAll ? ITEM_HEIGHT * itemCount : ITEM_HEIGHT;
          return height;
        };
        const [showArrows, setShowArrows] = useState(false);
        const handleNumberOfRoomsChange = (e) => {
          const value = e.target.value.trim(); // Trim to handle white spaces
          const newValue = value === '' ? null : Number(value);
          setShowArrows(true);
          setNumberOfRooms(newValue);
          onDataFiltered(newValue);
        };

        const handleMouseEnter = () => {
          setShowArrows(true);
        };
      
        const handleMouseLeave = () => {
          setShowArrows(false);
        };

        const handlePriceRangeChange = (newPriceRange) => {
          setPriceRange(newPriceRange);
          onPriceFiltered(newPriceRange); 
        };
    
        const [anchorEl, setAnchorEl] = useState(null);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
   
          
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        // Define your mapping of Serbian asset types to English asset types
        const assetTypeMapping = {
          'Potkrovlje': 'Loft',
          'Kuća': 'House',
          'Stan': 'Apartment',
          'Vila': 'Villa',
          'Oaza': 'Oasis',
          'Palata': 'Mansion',
          'Vikendica': 'Cottage',
          'Kuća na drvetu': 'Treehouse',
          'Šato': 'Chalet',
          'Kabina': 'Cabin',
          'Apartman': 'Suite'
          // Add more mappings here
        };

        const handleAssetTypeClick = (assetType) => {
          // Get the English value for the selected asset type
          const englishAssetType = assetTypeMapping[assetType] || assetType;
          // Handle the selected asset type
          setSelectedAssetType(assetType);
          handleClose();
          onAssetTypeFiltered(englishAssetType.toLowerCase());
        };


        const clearAllFilters = () => {
          setNumberOfRooms('');
          setPriceRange([0, 1000000]);
          setSelectedAssetType('');
          setCheckedItems({});
          // Notify the parent component (HomePage) about the filter reset
          if (props.onClearAllFilters) {
            props.onClearAllFilters();
          }
        };

        const handleIncrease = () => {
          setNumberOfRooms((prevValue) => {
            const newValue = Number(prevValue) + 0.5;
            onDataFiltered(newValue);
            return newValue;
          });
        };
        
        const handleDecrease = () => {
          if (numberOfRooms > 0) {
            setNumberOfRooms((prevValue) => {
              const newValue = Number(prevValue) - 0.5;
              onDataFiltered(newValue);
              return newValue;
            });
          }
        };
        // Define the updateFilterOrder function
      const updateFilterOrder = (userId, newFilterOrder) => {
        // Make an API call to update the filter order on the server
        fetch('/api/filterOrder/updateFilterOrder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId, newFilterOrder: newFilterOrder }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to update filter order on the server');
            }
            // Handle success response if needed
          })
          .catch((error) => {
            console.error('Error updating filter order:', error);
            // Handle error if needed
          });
      };


      const [isMobile, setIsMobile] = useState(false);
      const [maxWidth, setMaxWidth] = useState('350px');  

      useEffect(() => {
        // Detect if the user is on a mobile device
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('android') || userAgent.includes('iphone')) {
          setIsMobile(true);
    
          // Specific logic for Galaxy Z Fold 5 and Samsung Galaxy S8+
          if (userAgent.includes('galaxy z fold5') || userAgent.includes('sm-g955')) { // Example identifiers, adjust based on actual user agent strings
            setMaxWidth('300px'); // Adjust maxWidth for these devices
          }
        }
      }, []);

        const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);
      
        const onDragEnd = (result) => {
          if (!result.destination || !isDraggingEnabled) {
            return;
          }
          const newFilterOrder = Array.from(filterOrder);
          const [movedFilter] = newFilterOrder.splice(result.source.index, 1);
          newFilterOrder.splice(result.destination.index, 0, movedFilter);
          setFilterOrder(newFilterOrder);
          setFilterChanged(true);
        };
        

        const [isEditFiltersActive, setIsEditFiltersActive] = useState(false);
        const [assetTypeWidth, setAssetTypeWidth] = useState('100%');
        const [filterChanged, setFilterChanged] = useState(false);
        const [savedChanges, setSavedChanges] = useState(false);

useEffect(() => {
  if (savedChanges) {
    // Make an API call to update the filter order on the server
    updateFilterOrder(userId, filterOrder); // Pass userId and filterOrder to updateFilterOrder
  }
}, [savedChanges, filterOrder, userId]);

      const [removedFilters, setRemovedFilters] = useState([]);
    // Function to handle deleting filters
    const handleDeleteFilter = (filterName) => {
      // Update the state to remove the filter
      setFilterOrder((prevFilters) => {
        const updatedFilters = prevFilters.filter((filter) => filter !== filterName);
        setRemovedFilters((prevRemoved) => [...new Set([...prevRemoved, filterName])]); // Track removed filter
        return updatedFilters;
      });
      setFilterChanged(true); // Indicate that changes have been made
    };


        const [isAddFiltersModalOpen, setAddFiltersModalOpen] = useState(false);

         // Function to handle opening the Add Filters modal
        const handleOpenAddFiltersModal = () => {
          setAddFiltersModalOpen(true);
        };

        // Function to handle closing the Add Filters modal
        const handleCloseAddFiltersModal = () => {
          setAddFiltersModalOpen(false);
        };

        // Function to handle adding a new filter
        const handleAddFilter = (newFilters) => {
          // Convert single filter to an array if it's not already an array
          const filtersToAdd = Array.isArray(newFilters) ? newFilters : [newFilters];
        
          // Implement logic to add the new filters
          const updatedFilterOrder = [...filterOrder, ...filtersToAdd]
            .filter(filter => typeof filter === 'string'); // Filter out non-string values
          
          console.log(updatedFilterOrder); // Log the filter order before calling JSON.stringify()
          
          // Update the filter order state optimistically
          setFilterOrder(updatedFilterOrder);
        
          // Remove the restored filters from the list of removed filters
          setRemovedFilters(prevRemoved => {
            return prevRemoved.filter(removedFilter => !filtersToAdd.includes(removedFilter));
          });
        
          // Set other state variables
          setSavedChanges(true);
          setIsEditFiltersActive(false);
          setActiveButton('list'); // Activate the "Rent" button
          setAssetTypeWidth('100%');
          setFilterChanged(false);
          setIsDraggingEnabled(false);
        };
        
        

        const handleAddRestoredFilter = (filter) => {
          // Add the restored filter directly
          handleAddFilter(filter);
          handleCloseAddFiltersModal();
          console.log('Restored filter:', filter);
      
        };

        useEffect(() => {
          const storedRemovedFilters = localStorage.getItem(`removedFilters_${userId}`);
          try {
            if (storedRemovedFilters) {
              setRemovedFilters(JSON.parse(storedRemovedFilters));
            }
          } catch (error) {
            console.error('Error parsing removedFilters from localStorage:', error);
            // Handle the error if necessary
          }
        }, [userId]);
        
        useEffect(() => {
          try {
            localStorage.setItem(`removedFilters_${userId}`, JSON.stringify(removedFilters));
          } catch (error) {
            console.error('Error storing removedFilters in localStorage:', error);
          }
        }, [removedFilters, userId]);
        

        
      // const [isLoading, setIsLoading] = useState(true);
      // useEffect(() => {
      //   if (userId) { // Only fetch filter order if userId is available
      //     // Make an API call to fetch the filter order based on the userId
      //     const fetchFilterOrder = async () => {
      //       try {
      //         const response = await fetch(`/api/filterOrder/${userId}`);
      //         if (!response.ok) {
      //           throw new Error('Failed to fetch filter order');
      //         }
      //         const data = await response.json();
      //         setFilterOrder(data.filterOrder);
      //       } catch (error) {
      //         console.error('Error fetching filter order:', error);
      //       } finally {
      //         setIsLoading(false);
      //       }
      //     };
        
      //     fetchFilterOrder(); // Fetch filter order when component mounts
      //   }
      // }, [userId, setFilterOrder]); // Dependency array with userId
      
      // // Render loading indicator while isLoading is true
      // if (isLoading) {
      //   return <div>Loading...</div>;
      // }
      const [activeIcon, setActiveIcon] = useState(
        window.innerWidth >= 100 ? 'faders' : '' // Set default active icon to 'faders' for screens wider than or equal to 1100px
      );
      const handleIconClick = (iconName) => {
        setActiveIcon(iconName === activeIcon ? null : iconName);
        onClose();
      };
    
      if (isMobile) {
        // Placeholder for mobile content
        return (
          <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="filterDroppable" direction="vertical">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="scrollbar1"
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: 'calc(100vh - 80px)', 
                  flexShrink: 0,
                  borderRight: '1px solid #333840',
                  background: '#1A2033',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  zIndex: '1005px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', position: 'relative', justifyContent: 'center' }}>
                  <IconButton onClick={() => handleButtonClick('grid')} style={{ color: activeButton === 'grid' ? "#00D0BD" : "#FFFFFF" }}>
                    <Typography variant="h6" noWrap style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '20px',
                      fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, width: '80px', height: '24px', paddingRight: '20px',
                      textDecoration: activeButton === 'grid' ? 'underline' : 'none' }}>
                         {t('buy')}
                    </Typography>
                  </IconButton>
                  <IconButton onClick={() => handleButtonClick('list')} style={{ color: activeButton === 'list' ? "#00D0BD" : "#FFFFFF" }}>
                    <Typography variant="h6" noWrap style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '20px',
                      fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '24px',
                      textDecoration: activeButton === 'list' ? 'underline' : 'none' }}>
                         {t('rent')}
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={() => handleButtonClick('edit')}
                    style={{ color: activeButton === 'edit' ? "#00D0BD" : "#FFFFFF", width: '40px', left: '15%', position:'relative', bottom: '20px' }}
                  >
                    <div className='edit-container'>
                      <EditIcon  checked={isToggleOn} onChange={() => setIsToggleOn(!isToggleOn)}  color={activeButton === 'edit' ? "#00D0BD" : "#FFFFFF"}/>
                    </div>
                  </IconButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isAddFiltersModalOpen && (
                <AddFiltersModal
                  isOpen={isAddFiltersModalOpen}
                  onClose={handleCloseAddFiltersModal}
                  onAddFilter={handleAddRestoredFilter}
                  restoredFilters={removedFilters}
                />
              )}
              <IconButton onClick={clearAllFilters}>
                <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '15px', fontStyle: 'normal', fontWeight: '300', lineHeight: 'normal', flexShrink: 0,  height: '18px', paddingRight: '10px', justifyContent: 'flex-end' }}>
                {t('clearAll')}
                </Typography>
              </IconButton>
              <IconButton onClick={() => handleIconClick('faders')}>
                  <FadersHorizontal color={activeIcon === 'faders' ? '#00D0BD' : '#ffffff'} />
                </IconButton>
            </div>
            {isEditFiltersActive && (
                  <div style={{width: '90%', height: '50px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px', marginLeft: '6px', marginTop: '30px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                      <IconButton onClick={handleOpenAddFiltersModal} className='plusButton' style={{padding: '13px 5px'}}>
                          <AddIcon style={{color: 'white',}}/>
                      </IconButton>
                  </div>
              )}
              <div style={{marginTop: '20px'}}>
              {filterOrder.map((filter, index) => (
                <Draggable key={filter} draggableId={filter.toString()} index={index} isDragDisabled={!isDraggingEnabled}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >         
              {filter === 'Asset Type' && (
                        <div style={{ width: '100%', display: 'flex' }}>
                          {/* Asset Type component */}
                    <div style={{width: assetTypeWidth, height: '60px', flexShrink: 0, maxWidth: maxWidth,borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', position: 'relative', bottom: '0px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                      <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                        fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                       {t('assetType')}
                      </Typography>
                      <Button
                        onClick={handleClick}
                        style={{
                          color: selectedAssetType ? 'rgb(0, 208, 189)' : '#798680',
                          flexShrink: 0,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          textTransform: 'none',
                          fontFamily: 'Montserrat',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 300,
                          lineHeight: 'normal',
                          padding: '4px'
                        }}
                      >
                        {selectedAssetType || i18n.t('Apartment')}
                      </Button>
                      <Popover
                      className="myPopover"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <List style={{
                          background: 'linear-gradient(rgb(42, 47, 61) 100%, rgba(65, 0, 51, 0) 100%)',
                          flexDirection: 'column',
                          justifyContent: 'left',
                          display: 'flex',
                          width: '345px',
                        }}>
                          {assetTypes.map((assetType) => (
                            <ListItem
                              key={assetType}
                              onClick={() => handleAssetTypeClick(assetType)}
                              style={{ color: '#FFFFFF', cursor: 'pointer', fontFamily: 'Montserrat',  }}
                              className="my-button"
                            >
                              {assetType}
                            </ListItem>
                          ))}
                        </List>
                      </Popover>
                    </div>
                    {isEditFiltersActive && (
                        // Cross sign for deleting the filter
                        <IconButton
                          onClick={() => handleDeleteFilter(filter)}
                          style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                        >
                         <RemoveCircleIcon/>
                        </IconButton>
                      )}
                        </div>
                      )}
  {showAvailabilityFilter && filter === 'Availability' && (
                        <div style={{ width: '100%', display: 'flex' }}>
                    {/* Availability component */}
                    <div style={{width: assetTypeWidth, maxWidth: maxWidth ,flexShrink: 0,  borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none"}}>
                      <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                        fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0,  paddingLeft: '5px', paddingTop: '10px'}}>
                       {t('availability')}
                      </Typography>
                      <CalendarComponent width={isEditFiltersActive ? '60%' : '100%'} onDateChange={onAvailabilityFiltered} state={state} setState={setState} />
                    </div>
                     {isEditFiltersActive && (
                        // Cross sign for deleting the filter
                        <IconButton
                          onClick={() => handleDeleteFilter(filter)}
                          style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                        >
                         <RemoveCircleIcon/>
                        </IconButton>
                      )}
                        </div>
                )}
            
            {filter === 'Price Range' && (
                        <div style={{ display: 'flex' }}>
                    {/* Price Range component */}
                    <div style={{width: assetTypeWidth, maxWidth: maxWidth, height: '115px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none"}}>
                      <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                        fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                        {t('priceRange')}
                      </Typography>
                      <RangeSlider value={priceRange} onChange={handlePriceRangeChange}/>
                    </div>
                    {isEditFiltersActive && (
                        // Cross sign for deleting the filter
                        <IconButton
                          onClick={() => handleDeleteFilter(filter)}
                          style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                        >
                         <RemoveCircleIcon/>
                        </IconButton>
                      )}
                  </div>
                )}
              {/* Draggable for Number of Rooms */}
              {filter === 'Number Of Rooms' && (
                        <div style={{ width: '100%', display: 'flex' }}>
                    {/* Draggable Number of Rooms */}
                    <div style={{width: assetTypeWidth, maxWidth: maxWidth, height: '60px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none"}}>
                      <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                        fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0,  paddingLeft: '5px', paddingTop: '10px'}}>
                        {t('numberOfRooms')}
                      </Typography>
                      <InputBase 
                        placeholder='0'
                        value={numberOfRooms}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        endAdornment={
                          showArrows && (
                            <InputAdornment position="end" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'baseline' }}>
                              <IconButton onClick={handleIncrease} style={{ padding: '0px', height: '25px', justifyContent: 'center' }}>
                                <KeyboardArrowUpIcon style={{ color: '#00D0BD', padding: '0px' }} />
                              </IconButton>
                              <IconButton onClick={handleDecrease} style={{ padding: '0px', height: '5px', justifyContent: 'center', marginBottom: '15px' }}>
                                <KeyboardArrowDownIcon style={{ color: '#00D0BD', padding: '0px' }} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                        inputProps={{
                          style: {
                            color: '#FFF',
                            fontFamily: 'Montserrat',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: 'normal',
                            paddingLeft: '5px'
                          },
                          type: 'number', // Set the input type to number
                          step: '0.5',// Allow increments of 0.5
                          min: '0', // Set the minimum value to 0
                        }} 
                        onChange={handleNumberOfRoomsChange}
                      >
                      </InputBase>
                    </div>
                    {isEditFiltersActive && (
                        // Cross sign for deleting the filter
                        <IconButton
                          onClick={() => handleDeleteFilter(filter)}
                          style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                        >
                         <RemoveCircleIcon/>
                        </IconButton>
                      )}
                  </div>
                )}
                {/* Draggable Amenities*/}
                {filter === 'Amenities' && (
                        <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ height: getDivHeight(showAllAmenities, amenitiesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                <div>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                    {t('amenities')}
                  </Typography>
                  <RadioGroup  >
                    {renderAmenities()}
                  </RadioGroup>
                  <button
                    onClick={handleShowAllAmenities}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      height: '22px',
                      background: 'transparent',
                      color: 'rgb(0, 208, 189)',
                      border: 'none',
                      paddingLeft: '5px', // Remove default padding
                      outline: 'none', // Remove default focus border
                      transition: 'background 0.3s', // Add transition for hover effect
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.background = 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)')}
                    onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
                  >
                    {showAllAmenities ? i18n.t('Show Less') : `${i18n.t('Show All')} ${amenitiesList.length}`}
                    {showAllAmenities ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </button>
                </div>
              </div>
              {isEditFiltersActive && (
                        // Cross sign for deleting the filter
                        <IconButton
                          onClick={() => handleDeleteFilter(filter)}
                          style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                        >
                         <RemoveCircleIcon/>
                        </IconButton>
                      )}
            </div>
          )}
          {filter === 'SaleRent' && (
  <div style={{ width: '100%', display: 'flex' }}>
  <div style={{ height: 'auto', width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px',  marginLeft: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
    <div>
    <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
        {t('Sale / Rent')}
      </Typography>
      <RadioGroup>
        {renderSaleRentOptions()}
      </RadioGroup>
    </div>
  </div>
  {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}
          {/* Draggable host language*/}
          {filter === 'Host Language' && (
                        <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                <div>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                    {t('hostLanguage')}
                  </Typography>
                  <RadioGroup >
                    {renderLanguages()}
                  </RadioGroup>
                  <button
                    onClick={handleShowAllLanguages}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      height: '22px',
                      background: 'transparent',
                      color: 'rgb(0, 208, 189)',
                      border: 'none',
                      paddingLeft: '5px', 
                      outline: 'none', 
                      transition: 'background 0.3s', 
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.background = 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)')}
                    onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
                  >
                    {showAllLanguages ? i18n.t('Show Less') : `${i18n.t('Show All')}  ${languagesList.length}`}
                    {showAllLanguages ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </button>
                </div>
              </div>
              {isEditFiltersActive && (
                        // Cross sign for deleting the filter
                        <IconButton
                          onClick={() => handleDeleteFilter(filter)}
                          style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                        >
                         <RemoveCircleIcon/>
                        </IconButton>
                      )}
            </div>
          )}
           {/* Draggable Furniture*/}
          {filter === 'Furniture' && (
            <div style={{ width: '100%', display: 'flex' }}>
                          <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                <div>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                    {t('Furniture')}
                  </Typography>
                  <RadioGroup>
                  {renderFurniture()}
                  </RadioGroup>
                </div>
              </div>
              {isEditFiltersActive && (
                // Cross sign for deleting the filter
                <IconButton
                  onClick={() => handleDeleteFilter(filter)}
                  style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                >
                <RemoveCircleIcon/>
                </IconButton>
              )}
            </div>
          )}
                {/* Draggable Asset Condition*/}
              {filter === 'Asset Condition' && (
              <div style={{ width: '100%', display: 'flex' }}>
                           <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                  <div>
                    <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                      fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                      {t('Asset Condition')}
                    </Typography>
                    <RadioGroup>
                    {renderAssetCondition()}
                    </RadioGroup>
                  </div>
                </div>
                {isEditFiltersActive && (
                  // Cross sign for deleting the filter
                  <IconButton
                    onClick={() => handleDeleteFilter(filter)}
                    style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                  >
                  <RemoveCircleIcon/>
                  </IconButton>
                )}
              </div>
            )}

            {/* Draggable Heating*/}
            {filter === 'Heating' && (
              <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                  <div>
                    <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                      fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                      {t('Heating')}
                    </Typography>
                    <RadioGroup>
                    {renderHeating()}
                    </RadioGroup>
                  </div>
                </div>
                {isEditFiltersActive && (
                  // Cross sign for deleting the filter
                  <IconButton
                    onClick={() => handleDeleteFilter(filter)}
                    style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                  >
                  <RemoveCircleIcon/>
                  </IconButton>
                )}
              </div>
            )}
            {/* Draggable Status */}
            {filter === 'Status' && (
              <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                  <div>
                    <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                      fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                      {t('Status')}
                    </Typography>
                    <RadioGroup>
                      {renderStatus()}
                    </RadioGroup>
                  </div>
                </div>
                {isEditFiltersActive && (
                  // Cross sign for deleting the filter
                  <IconButton
                    onClick={() => handleDeleteFilter(filter)}
                    style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                  >
                  <RemoveCircleIcon/>
                  </IconButton>
                )}
              </div>
            )}
            {/* Draggable Hidden Assets */}
            {filter === 'Hidden Assets' && (
              <div style={{ width: '100%', display: 'flex' }}>
                           <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, maxWidth: maxWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '20px',  marginLeft: '16px', marginRight: '16px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                  <div>
                    <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                      fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                      {t('Hidden Assets')}
                    </Typography>
                    <RadioGroup>
                      {renderHiddenAssets()}
                    </RadioGroup>
                  </div>
                </div>
                {isEditFiltersActive && (
                  // Cross sign for deleting the filter
                  <IconButton
                    onClick={() => handleDeleteFilter(filter)}
                    style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                  >
                  <RemoveCircleIcon/>
                  </IconButton>
                )}
              </div>
            )}
        </div>
                  )} 
                </Draggable> 
              ))} 
              </div>
                        {filterChanged && (
                <Button variant="contained" onClick={handleAddFilter} style={{ flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', }}>
                  Save Changes
                </Button>
              )}
              {provided.placeholder}
          </div>
           )}
                </Droppable>
                </DragDropContext>
        );
    } else {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="filterDroppable" direction="vertical">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="scrollbar1"
            style={{
              position: 'fixed',
              top: 0,
              left: leftPosition,
              width: '250px',
              height: '100vh',
              flexShrink: 0,
              borderRight: '1px solid #333840',
              background: '#1A2033',
              overflow: 'scroll',
              overflowX: 'hidden',
              zIndex: '1005px'
            }}
          >
                  <div
        style={{
          background: '#1A2033',
        }}
      >
       <Typography variant="body1" style={{ color: 'white', textAlign: 'left', marginTop: '20px', paddingLeft: '15px',  paddingBottom: '15px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}>
       {t('Filters')}
         </Typography>
         <div style={{ borderTop: '1px solid gray', marginBottom: '10px' }}></div>
        </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <IconButton onClick={() => handleButtonClick('grid')} style={{ color: activeButton === 'grid' ? "#00D0BD" : "#FFFFFF" }}>
                <Typography variant="h6" noWrap style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '20px',
                  fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, width: '80px', height: '24px', paddingRight: '20px',
                  textDecoration: activeButton === 'grid' ? 'underline' : 'none' }}>
                     {t('buy')}
                </Typography>
              </IconButton>
              <IconButton onClick={() => handleButtonClick('list')} style={{ color: activeButton === 'list' ? "#00D0BD" : "#FFFFFF" }}>
                <Typography variant="h6" noWrap style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '20px',
                  fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '24px',
                  textDecoration: activeButton === 'list' ? 'underline' : 'none' }}>
                     {t('rent')}
                </Typography>
              </IconButton>
              <IconButton
                onClick={() => handleButtonClick('edit')}
                style={{ color: activeButton === 'edit' ? "#00D0BD" : "#FFFFFF", width: '40px', marginLeft: 'auto', position:'relative', bottom: '20px' }}
              >
                <div className='edit-container'>
                  <EditIcon  checked={isToggleOn} onChange={() => setIsToggleOn(!isToggleOn)}  color={activeButton === 'edit' ? "#00D0BD" : "#FFFFFF"}/>
                </div>
              </IconButton>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {isAddFiltersModalOpen && (
            <AddFiltersModal
              isOpen={isAddFiltersModalOpen}
              onClose={handleCloseAddFiltersModal}
              onAddFilter={handleAddRestoredFilter}
              restoredFilters={removedFilters}
            />
          )}
          <IconButton onClick={clearAllFilters}>
            <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '15px', fontStyle: 'normal', fontWeight: '300', lineHeight: 'normal', flexShrink: 0,  height: '18px', paddingRight: '10px', justifyContent: 'flex-end' }}>
            {t('clearAll')}
            </Typography>
          </IconButton>
        </div>
        {isEditFiltersActive && (
              <div style={{width: assetTypeWidth, height: '30px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px',  animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                  <IconButton onClick={handleOpenAddFiltersModal} className='plusButton' style={{padding: '3px 5px'}}>
                      <AddIcon style={{color: 'white',}}/>
                  </IconButton>
              </div>
          )}
          {filterOrder.map((filter, index) => (
            <Draggable key={filter} draggableId={filter.toString()} index={index} isDragDisabled={!isDraggingEnabled}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >         
          {filter === 'Asset Type' && (
                    <div style={{ width: '100%', display: 'flex' }}>
                      {/* Asset Type component */}
                <div style={{width: assetTypeWidth, height: '60px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px',  animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                   {t('assetType')}
                  </Typography>
                  <Button
                    onClick={handleClick}
                    style={{
                      color: selectedAssetType ? 'rgb(0, 208, 189)' : '#798680',
                      flexShrink: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textTransform: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 300,
                      lineHeight: 'normal',
                      padding: '4px'
                    }}
                  >
                    {selectedAssetType || i18n.t('Apartment')}
                  </Button>
                  <Popover
                  className="myPopover"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <List style={{
                      background: 'linear-gradient(rgb(42, 47, 61) 100%, rgba(65, 0, 51, 0) 100%)',
                      flexDirection: 'column',
                      display: 'flex',
                      width: '245px',
                    }}>
                      {assetTypes.map((assetType) => (
                        <ListItem
                          key={assetType}
                          onClick={() => handleAssetTypeClick(assetType)}
                          style={{ color: '#FFFFFF', cursor: 'pointer', fontFamily: 'Montserrat',  }}
                          className="my-button"
                        >
                          {assetType}
                        </ListItem>
                      ))}
                    </List>
                  </Popover>
                </div>
                {isEditFiltersActive && (
                    // Cross sign for deleting the filter
                    <IconButton
                      onClick={() => handleDeleteFilter(filter)}
                      style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                    >
                     <RemoveCircleIcon/>
                    </IconButton>
                  )}
                    </div>
                  )}
  {showAvailabilityFilter && filter === 'Availability' && (
                    <div style={{ width: '100%', display: 'flex' }}>
                {/* Availability component */}
                <div style={{ '--calendar-width': isEditFiltersActive ? '100%' : '100%', width: assetTypeWidth, flexShrink: 0, maxHeight: '370px', borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none"}}>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0,  paddingLeft: '5px', paddingTop: '10px'}}>
                   {t('availability')}
                  </Typography>
                  <CalendarComponent width={isEditFiltersActive ? '60%' : '100%'} onDateChange={onAvailabilityFiltered}  state={state} setState={setState} />
                </div>
                 {isEditFiltersActive && (
                    // Cross sign for deleting the filter
                    <IconButton
                      onClick={() => handleDeleteFilter(filter)}
                      style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                    >
                     <RemoveCircleIcon/>
                    </IconButton>
                  )}
                    </div>
            )}
        
        {filter === 'Price Range' && (
                    <div style={{ width: '100%', display: 'flex' }}>
                {/* Price Range component */}
                <div style={{width: assetTypeWidth, height: '115px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none"}}>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                    {t('priceRange')}
                  </Typography>
                  <RangeSlider value={priceRange} onChange={handlePriceRangeChange}/>
                </div>
                {isEditFiltersActive && (
                    // Cross sign for deleting the filter
                    <IconButton
                      onClick={() => handleDeleteFilter(filter)}
                      style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                    >
                     <RemoveCircleIcon/>
                    </IconButton>
                  )}
              </div>
            )}
          {/* Draggable for Number of Rooms */}
          {filter === 'Number Of Rooms' && (
                    <div style={{ width: '100%', display: 'flex' }}>
                {/* Draggable Number of Rooms */}
                <div style={{width: assetTypeWidth, height: '60px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none"}}>
                  <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                    fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0,  paddingLeft: '5px', paddingTop: '10px'}}>
                    {t('numberOfRooms')}
                  </Typography>
                  <InputBase 
                    placeholder='0'
                    value={numberOfRooms}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    endAdornment={
                      showArrows && (
                        <InputAdornment position="end" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'baseline' }}>
                          <IconButton onClick={handleIncrease} style={{ padding: '0px', height: '25px', justifyContent: 'center' }}>
                            <KeyboardArrowUpIcon style={{ color: '#00D0BD', padding: '0px' }} />
                          </IconButton>
                          <IconButton onClick={handleDecrease} style={{ padding: '0px', height: '5px', justifyContent: 'center', marginBottom: '15px' }}>
                            <KeyboardArrowDownIcon style={{ color: '#00D0BD', padding: '0px' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    inputProps={{
                      style: {
                        color: '#FFF',
                        fontFamily: 'Montserrat',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: 'normal',
                        paddingLeft: '5px'
                      },
                      type: 'number', // Set the input type to number
                      step: '0.5',// Allow increments of 0.5
                      min: '0', // Set the minimum value to 0
                    }} 
                    onChange={handleNumberOfRoomsChange}
                  >
                  </InputBase>
                </div>
                {isEditFiltersActive && (
                    // Cross sign for deleting the filter
                    <IconButton
                      onClick={() => handleDeleteFilter(filter)}
                      style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                    >
                     <RemoveCircleIcon/>
                    </IconButton>
                  )}
              </div>
            )}
            {/* Draggable Amenities*/}
            {filter === 'Amenities' && (
                    <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ height: amenitiesList ? getDivHeight(showAllAmenities, amenitiesList.length) : 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
            <div>
              <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                {t('amenities')}
              </Typography>
              <RadioGroup  >
                {renderAmenities()}
              </RadioGroup>
              <button
                onClick={handleShowAllAmenities}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  height: '22px',
                  background: 'transparent',
                  color: 'rgb(0, 208, 189)',
                  border: 'none',
                  paddingLeft: '5px', // Remove default padding
                  outline: 'none', // Remove default focus border
                  transition: 'background 0.3s', // Add transition for hover effect
                }}
                onMouseOver={(e) => (e.currentTarget.style.background = 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)')}
                onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
              >
                {showAllAmenities ? i18n.t('Show Less') : `${i18n.t('Show All')} ${amenitiesList.length}`}
                {showAllAmenities ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </button>
            </div>
          </div>
          {isEditFiltersActive && (
                    // Cross sign for deleting the filter
                    <IconButton
                      onClick={() => handleDeleteFilter(filter)}
                      style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                    >
                     <RemoveCircleIcon/>
                    </IconButton>
                  )}
        </div>
      )}
      {/* Draggable Furniture*/}
{filter === 'Furniture' && (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ height: 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
      <div>
        <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
          {t('Furniture')}
        </Typography>
        <RadioGroup>
        {renderFurniture()}
        </RadioGroup>
      </div>
    </div>
    {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}
  {/* Draggable Asset Condition*/}
  {filter === 'Asset Condition' && (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ height: 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
      <div>
        <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
          {t('Asset Condition')}
        </Typography>
        <RadioGroup>
        {renderAssetCondition()}
        </RadioGroup>
      </div>
    </div>
    {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}

 {/* Draggable Heating*/}
 {filter === 'Heating' && (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ height: 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
      <div>
        <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
          {t('Heating')}
        </Typography>
        <RadioGroup>
        {renderHeating()}
        </RadioGroup>
      </div>
    </div>
    {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}
{/* Draggable Status */}
{filter === 'Status' && (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ height: 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
      <div>
        <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
          {t('Status')}
        </Typography>
        <RadioGroup>
          {renderStatus()}
        </RadioGroup>
      </div>
    </div>
    {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}
{/* Draggable Hidden Assets */}
{filter === 'Hidden Assets' && (
  <div style={{ width: '100%', display: 'flex' }}>
    <div style={{ height: 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
      <div>
        <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
          {t('Hidden Assets')}
        </Typography>
        <RadioGroup>
          {renderHiddenAssets()}
        </RadioGroup>
      </div>
    </div>
    {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}
{filter === 'SaleRent' && (
  <div style={{ width: '100%', display: 'flex' }}>
  <div style={{ height: 'auto', width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
    <div>
    <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
          fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
        {t('Sale / Rent')}
      </Typography>
      <RadioGroup>
        {renderSaleRentOptions()}
      </RadioGroup>
    </div>
  </div>
  {isEditFiltersActive && (
      // Cross sign for deleting the filter
      <IconButton
        onClick={() => handleDeleteFilter(filter)}
        style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
      >
       <RemoveCircleIcon/>
      </IconButton>
    )}
  </div>
)}
      {/* Draggable host language*/}
      {filter === 'Host Language' && (
                    <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ height: getDivHeight(showAllLanguages, languagesList.length), width: assetTypeWidth, flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', animation: isEditFiltersActive ? "shake 2s infinite" : "none" }}>
            <div>
              <Typography variant="h6" noWrap style={{ display: 'flex', flexDirection: 'row', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px',
                fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', flexShrink: 0, paddingLeft: '5px', paddingTop: '10px'}}>
                {t('hostLanguage')}
              </Typography>
              <RadioGroup >
                {renderLanguages()}
              </RadioGroup>
              <button
                onClick={handleShowAllLanguages}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  height: '22px',
                  background: 'transparent',
                  color: 'rgb(0, 208, 189)',
                  border: 'none',
                  paddingLeft: '5px', 
                  outline: 'none', 
                  transition: 'background 0.3s', 
                }}
                onMouseOver={(e) => (e.currentTarget.style.background = 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)')}
                onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
              >
                {showAllLanguages ? i18n.t('Show Less') : `${i18n.t('Show All')}  ${languagesList.length}`}
                {showAllLanguages ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </button>
            </div>
          </div>
          {isEditFiltersActive && (
                    // Cross sign for deleting the filter
                    <IconButton
                      onClick={() => handleDeleteFilter(filter)}
                      style={{ position: 'relative', bottom: 0, right: 0 , color: 'gray',}}
                    >
                     <RemoveCircleIcon/>
                    </IconButton>
                  )}
        </div>
      )}
    </div>
              )} 
            </Draggable> 
          ))} 
                    {filterChanged && (
            <Button variant="contained" onClick={handleAddFilter} style={{ flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginBottom: '10px', maxWidth: '240px', marginLeft: '6px', }}>
              Save Changes
            </Button>
          )}
          {provided.placeholder}
      </div>
       )}
            </Droppable>
            </DragDropContext>
    );
  };
  };

  export default FilterComponent;