import React, { useEffect, useState } from 'react';
import { Button, DialogTitle, Input, Select, Typography } from '@material-ui/core';
import { Box, Dialog, DialogActions, DialogContent, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {ThemeProvider } from '@mui/material/styles';
import { CustomAddCancelButton } from '../shared/buttons';
import { theme, useStyles } from '../styles/ClientsTabStyle';

function ClientsTab({ assetId, userId , clientsTabRef, selectRef, setMenuOpen, setActiveElement, isEditing , isClientsTabActive}) {
  const [contacts, setContacts] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '' });
  const [contactTypes, setContactTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

  useEffect(() => {
    if (isClientsTabActive) { // Check if the Clients tab is active
      fetch(`${API_URL}/api/contact_types`)
        .then(response => response.json())
        .then(data => setContactTypes(data));
    }
  }, [isClientsTabActive]); 

  const classes = useStyles();
  
  useEffect(() => {
    if (isClientsTabActive) { // Check if the Clients tab is active
      fetch(`${API_URL}/api/contact-names/${userId}`)
        .then(response => response.json())
        .then(data => setContacts(data))
        .catch(error => console.error('Error:', error));
    }
  }, [userId, isClientsTabActive]);

  useEffect(() => {
    if (isClientsTabActive) { // This checks if the Clients tab is active before fetching
      fetch(`${API_URL}/api/data/${assetId}/contacts`)
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            setClients(data);
          } else {
            setClients([]);
          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
        });
    }
  }, [isClientsTabActive]); // Include assetId in the dependency array if it's used in the fetch URL

  const addContactTypeRelation = () => {
    console.log('formData.name:', formData.name); // log formData.name
    console.log('contacts:', contacts); // log contacts array
      // Find the selected contact based on the name
  const selectedContact = contacts.find(contact => contact.name === formData.name);

  // If the selected contact is found, use its contact_id
  const contact_id = selectedContact ? selectedContact.contact_id : null;
  console.log('contact_id:', contact_id); // log contact_id
  console.log('type_id:', selectedType); // log type_id
  console.log('asset_id:', assetId); // log asset_id

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        contact_id: contact_id, // replace with the actual contact_id
        type_id: selectedType, // replace with the actual type_id
        asset_id: assetId, // include the assetId
      }),
    };
    console.log(contact_id, selectedType, assetId);
    fetch(`${API_URL}/api/contact_type_relations`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Refresh the clients data
        fetch(`${API_URL}/api/data/${assetId}/contacts`)
          .then(response => response.json())
          .then(data => {
            if (Array.isArray(data)) {
              setClients(data);
            } else {
              setClients([]);
            }
            setLoading(false);
            handleCloseAddModal(); // Close the modal
            resetForm(); // Reset the form
          })
          .catch(error => {
            console.error('Error:', error);
            setLoading(false);
          });
      })
      .catch(error => console.error('Error:', error));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Add this line
    setModalOpen(false);
    console.log(formData);
  };
  
  const removeClient = (contact_id, type_id) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    console.log("contact id", contact_id)
    console.log("type_id id", type_id)
    console.log("asset_id id", assetId)
    fetch(`${API_URL}/api/contact_type_relations/${contact_id}/${type_id}/${assetId}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete contact type relation');
        }
        return response.json();
      })
      .then(() => {
        console.log('Contact type relation deleted successfully');
             // Refresh logic: Update the clients state to remove the deleted client
      setClients(currentClients => currentClients.filter(client => client.contact_id !== contact_id));
      })
      .catch(error => console.error('Error:', error));
  };
  const handleInputChange = (event) => {
    event.stopPropagation();
    if (event.target.name === 'name') {
      const selectedContact = contacts.find(contact => contact.name === event.target.value);
      if (selectedContact) {
        setFormData({
          name: selectedContact.name,
          email: selectedContact.email, // replace 'email' with the actual property name in the contact object
          phone_number: selectedContact.phone_number // replace 'phoneNumber' with the actual property name in the contact object
        });
      } 
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      });
    }
  };

  const resetForm = () => {
    setFormData({ name: '', email: '', phoneNumber: '' });
    setSelectedType('');
  };

  if (loading) {
    return <Typography component="div" style={{fontFamily: 'Montserrat', color: '#FFF'}}>Loading...</Typography>;
  }



  const handleOpenAddModal = (event) => {
    event.stopPropagation();
    setModalOpen(true);
  };
  
  const handleCloseAddModal = (event) => {
    event.stopPropagation();
    setModalOpen(false);
  };
  
  return (
    <ThemeProvider theme={theme}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <div style={{width: '150px', height: '40px', flexShrink: 0, borderRadius: '10px', border: '1px solid #1F2C41', background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column'}}>
    <IconButton onClick={(event) => handleOpenAddModal(event)} className='plusButton' style={{padding: '6px 5px'}}>
  <AddIcon style={{color: 'white'}}/>
</IconButton>
            </div>
            <Dialog 
            ref={clientsTabRef}
            open={modalOpen} 
            onClose={handleCloseAddModal} 
            onClick={(event) => event.stopPropagation()}
            PaperProps={{
              style: {
                backgroundColor: '#1A2033', // Directly setting the background color
              },
            }}
            >
        <DialogTitle style={{color: '#FFF', fontFamily: 'Montserrat'}}>Add a New Interested Party</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
          <Box display="flex" flexDirection="column">
          <Select
            ref={selectRef}
            onClick={(event) => event.stopPropagation()}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            onOpen={() => {
                setActiveElement('menu');
            }}
            onClose={() => {
                setActiveElement(null);
            }}
            required
            displayEmpty
            style={{ paddingLeft: '15px'}}
            classes={{
                select: classes.select, // apply custom underline color
                icon: classes.icon, // apply custom dropdown arrow color
            }}
            inputProps={{
                classes: {
                selectMenu: classes.selectMenu, // apply custom selected value color
                },
            }}
            MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: '#1A2033',
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null, // This prevents the dropdown list from being positioned based on the selected item
              }}
            input={
                <Input
                classes={{
                    underline: classes.underline,
                }}
                />
            }
            >
            <MenuItem value="" disabled>
                <em style={{ color: '#FFF', fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '14px', padding: '5px', }}>Contact Name</em>
            </MenuItem>
            {contacts.map((contact, index) => (
            <MenuItem
            key={`${contact.name}-${index}`} 
            value={contact.name}
            style={{ color: '#FFF', display: 'block', paddingLeft: '15px' }}
            onMouseEnter={(e) => (e.target.style.color = 'rgb(0, 208, 189)')}
            onMouseLeave={(e) => (e.target.style.color = '#FFF')}
            >
            {contact.name}
            </MenuItem>
            ))}
            </Select>
            <TextField 
            name="email" 
            label="Email" 
            value={formData.email} 
            onChange={handleInputChange} 
            required 
            disabled  
            InputLabelProps={{
                style: { top: '-10px', color: 'gray' },
                shrink: formData.email ? true : false, // Corrected here
            }}
            style={{ marginTop: '20px'}}
            />
            <TextField 
            name="phone_number" 
            label="Phone Number" 
            value={formData.phone_number} 
            onChange={handleInputChange} 
            required 
            disabled  
            InputLabelProps={{
                style: { top: '-10px', color: 'gray' },
                shrink: formData.phone_number ? true : false, // Corrected here
            }}
            style={{marginTop: '20px'}}
            />
            <Select
  ref={selectRef}
  onClick={(event) => event.stopPropagation()}
  name="contactType"
  value={selectedType}
  onChange={(event) => setSelectedType(event.target.value)}
  onOpen={() => {
    setActiveElement('menu');
  }}
  onClose={() => {
    setActiveElement(null);
  }}
  required
  displayEmpty
  style={{marginBottom: '10px', marginTop: '20px', paddingLeft: '15px'}}
  classes={{
    select: classes.select,
    icon: classes.icon,
  }}
  inputProps={{
    classes: {
      selectMenu: classes.selectMenu,
    },
  }}
  MenuProps={{
    PaperProps: {
      style: {
        backgroundColor: '#1A2033',
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }}
  input={
    <Input
      classes={{
        underline: classes.underline,
      }}
    />
  }
>
  <MenuItem value="" disabled>
  <em style={{ color: '#FFF', fontFamily: 'Montserrat', fontStyle: 'normal', fontSize: '14px', padding: '0px', paddingLeft: '5px' }}>Contact Type</em>
  </MenuItem>
  {contactTypes.map(type => (
  <MenuItem
    key={type.type_id}
    value={type.type_id} // use type_id as value
    style={{ color: '#FFF', display: 'block', paddingLeft: '15px' }}
    onMouseEnter={(e) => (e.target.style.color = 'rgb(0, 208, 189)')}
    onMouseLeave={(e) => (e.target.style.color = '#FFF')}
  >
    {type.type_name}
  </MenuItem>
))}
</Select>
        </Box>
            <DialogActions>
            <div
            style={{
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#1A2033',
            }}>
  <CustomAddCancelButton  onClick={(event) => { handleCloseAddModal(event); resetForm(); }}>Cancel</CustomAddCancelButton>
  <CustomAddCancelButton  onClick={addContactTypeRelation}  type="submit">Add</CustomAddCancelButton>
        </div>
</DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {clients.length === 0 ? (
  <Typography  component="div" style={{fontFamily: 'Montserrat', color: '#FFF', marginTop: '20px'}}>There are no potential buyers for this asset yet.</Typography>
) : (
  <>
    <div className={classes.tableView}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell><Typography component="div" className={classes.customFont}>Name</Typography></TableCell>
            <TableCell><Typography component="div" className={classes.customFont}>Email</Typography></TableCell>
            <TableCell><Typography component="div" className={classes.customFont}>Phone Number</Typography></TableCell>
            <TableCell><Typography  component="div"className={classes.customFont}>Date</Typography></TableCell>
            <TableCell><Typography  component="div"className={classes.customFont}>Time</Typography></TableCell>
            <TableCell><Typography  component="div" className={classes.customFont}>Type</Typography></TableCell>
            {isEditing && <TableCell><Typography  component="div"className={classes.customFont}>Action</Typography></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map(client => (
            <TableRow key={client.contact_id}>
              <TableCell><Typography component="div" className={classes.customFont}>{client.name}</Typography></TableCell>
              <TableCell><Typography component="div" className={classes.customFont}>{client.email}</Typography></TableCell>
              <TableCell><Typography  component="div"className={classes.customFont}>{client.phone_number}</Typography></TableCell>
              <TableCell>
                <Typography component="div" className={classes.customFont}>
                  {client.meeting_date ? new Date(client.meeting_date).toLocaleDateString('de-DE') : ''}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="div" className={classes.customFont}>
                  {client.meeting_time ? client.meeting_time.split(':').slice(0, 2).join(':') : ''}
                </Typography>
              </TableCell>
              <TableCell><Typography  component="div" className={classes.customFont}>{client.type_name}</Typography></TableCell>
              {isEditing && (
                <TableCell>
                  <Button style={{ color: 'rgb(0, 208, 189)', textTransform: 'capitalize' }} onClick={() => removeClient(client.contact_id, client.type_id, client.assetId)}>Remove</Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
    <div className={classes.mobileView}>
      {clients.map(client => (
        <div key={client.contact_id} className={classes.clientCard}>
          <Typography component="div" className={classes.customFont}>Name: {client.name}</Typography>
          <Typography component="div" className={classes.customFont}>Email: {client.email}</Typography>
          <Typography  component="div"className={classes.customFont}>Phone Number: {client.phone_number}</Typography>
          <Typography component="div" className={classes.customFont}>
            Date: {client.meeting_date ? new Date(client.meeting_date).toLocaleDateString('de-DE') : ''}
          </Typography>
          <Typography component="div" className={classes.customFont}>
            Time: {client.meeting_time ? client.meeting_time.split(':').slice(0, 2).join(':') : ''}
          </Typography>
          <Typography component="div" className={classes.customFont}>Type: {client.type_name}</Typography>
          {isEditing && (
            <Button style={{ color: 'rgb(0, 208, 189)', textTransform: 'capitalize', marginTop: '10px', padding: '0px' }} onClick={() => removeClient(client.contact_id, client.type_id, client.assetId)}>Remove</Button>
          )}
        </div>
      ))}
    </div>
  </>
)}
    </div>
  </ThemeProvider>
  );
}

export default ClientsTab;