import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

const FilterContactsComponent = ( ) => {
    const [isMobile, setIsMobile] = useState(false);
        
    useEffect(() => {
      // Detect if the user is on a mobile device
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('android') || userAgent.includes('iphone')) {
        setIsMobile(true);
      }
    }, []);


    if (isMobile) {
        // Placeholder for mobile content
        return (
          <div
          style={{
            position: 'fixed',
            top: 0,
            left: '60px',
            width: '250px',
            height: '100vh',
            flexShrink: 0,
            borderRight: '1px solid #333840',
            background: '#1A2033',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Typography style={{marginTop: '20px', paddingLeft: '10px', color: "#FFF"}}>Filters for Contacts!</Typography>
          </div>
      );
        } else {
            return (
                <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: '60px',
                  width: '250px',
                  height: '100vh',
                  flexShrink: 0,
                  borderRight: '1px solid #333840',
                  background: '#1A2033',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                <Typography style={{marginTop: '20px', paddingLeft: '10px', color: "#FFF"}}>Filters for Contacts!</Typography>
                </div>
            );
            };
        };
      
        export default FilterContactsComponent;
