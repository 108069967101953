import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { IconButton, Paper, TextField, ThemeProvider, createTheme, makeStyles, useTheme } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import '../styles/EventCalendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Children, cloneElement } from "react";

const localizer = momentLocalizer(moment);
const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: "rgb(74, 79, 94)"
          },
          "&:hover $notchedOutline": {
            borderColor: "rgb(0, 208, 189)"
          },
          "&$focused $notchedOutline": {
            borderColor: "rgb(0, 208, 189)"
          },
      
        },


      },
      MuiFormLabel: { // Add this to change the color of the label
        root: {
          "&$focused": {
            color: 'white'
          },
          color: 'white'
    
        }
      },
      MuiInputLabel: {
        root: {
            "&$focused": {
              color: 'white'
            },
            color: 'white',

          }
      },
      MuiInputBase: {
        color: 'white',
      }
    }
  });
  

  const useStyles = makeStyles((theme) => ({
    inputRoot: {
      color: 'white',
      "& .MuiAutocomplete-clearIndicator": {
        color: 'white',

        bottom: '6px' 
      },
      "& .MuiAutocomplete-popupIndicator": {
        color: 'white'
      }
    },
    timePicker: {
        color: 'white',
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "rgb(74, 79, 94)",
          },
          "&:hover fieldset": {
            borderColor: "rgb(0, 208, 189)",
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgb(0, 208, 189)",
          },
      
          paddingLeft: '10px',
          top: '16px',
          height: '56px',
          width: '270px'
        },
        "& .MuiInputLabel-root": {
            color: 'white',
            top: '15px'
      },
        "& .MuiInputBase-input": {
          height: '38px',
          marginBottom: '6px',
          color: 'white',
        },
        "& .MuiSvgIcon-root": {
            color: 'white'
        }
      },
  }));

function EventCalendar({ contact_id, events, onAddEvent, selectedDate, selectedTime, setSelectedDate, setSelectedTime, eventTitle, setEventTitle, assets, setAssets, userId, setSelectedAsset, selectedAsset, setEvents, notes, setNotes, timePickerRef, isTimePIckerOpen, setIsTimePickerOpen }) {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [filteredEventsWithDates, setFilteredEventsWithDates] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsUpdated, setEventsUpdated] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const classes = useStyles();
  // Modify the handleSelect function to set the selected event and populate the form fields
  const handleSelect = (event) => {
    setSelectedEvent(event);
    setSelectedDate(moment(event.start).format('YYYY-MM-DD'));
    setSelectedTime(moment(event.start).format('hh:mm A')); // Use 'hh:mm A' format
    setEventTitle(event.title);
    setSelectedAsset(assets.find(asset => asset.id === event.asset_id));
    setNotes(event.notes);
  };
  
  // Handle when an empty slot is clicked
  const handleSelectSlot = (slotInfo) => {
    console.log("Slot clicked:", slotInfo); // Log the entire slotInfo object
    setSelectedEvent(null);
    setSelectedDate(moment(slotInfo.start).format('YYYY-MM-DD'));
    setSelectedTime(moment(slotInfo.start).format('HH:mm')); // Set selectedTime to the time of the start
    setSelectedTime(null); // This line seems to reset the selectedTime immediately after setting it
    setEventTitle('');
    setSelectedAsset(null);
    setNotes('');
  };
  // Modify the handleAddEvent function to check if an event is being edited or a new event is being added
  const handleAddEvent = (e) => {
    e.preventDefault();
    if (!selectedDate || !selectedTime) {
      alert('Please select a date and time.');
      return;
    }
    console.log(`Selected date: ${selectedDate}`);
    console.log(`Selected time: ${selectedTime}`);
    const parsedTime = moment(selectedTime, 'hh:mm A').format('HH:mm:ss');
    console.log(`Parsed time: ${parsedTime}`);
    const dateTime = moment(`${selectedDate}T${parsedTime}`);
    if (!dateTime.isValid()) {
      alert('Invalid date or time.');
      return;
    }
    const date = dateTime.format('YYYY-MM-DD');
    const time = dateTime.format('HH:mm:ss');
    const event = {   asset_id: selectedAsset ? selectedAsset.id : null, title: eventTitle, date, time, contact_id: contact_id,  notes: notes, };
    if (selectedEvent) {
      // If an event is being edited, send a PUT request to update the event
      fetch(`${API_URL}/api/events/${selectedEvent.event_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
      })
      .then(() => {
        setFilteredEventsWithDates(prevEvents => prevEvents.map(prevEvent => prevEvent.event_id === selectedEvent.event_id ? { ...prevEvent, ...event } : prevEvent));
        setSelectedEvent(null);
        setSelectedDate(null);
        setEventsUpdated(prevState => !prevState);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    } else {
        // If a new event is being added, send a POST request to create the event
        fetch(`${API_URL}/api/events`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(event),
        })
        .then(response => response.json())
        .then(data => {
            setFilteredEventsWithDates(prevEvents => [...prevEvents, { ...event, id: data.event_id }]);
            setSelectedDate(null);
            setEventsUpdated(prevState => !prevState);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      }
  };
  
  // Modify the deleteEvent function to set the selected event to null after deleting it
  const deleteEvent = (eventToDelete) => {
    fetch(`${API_URL}/api/events/${eventToDelete.event_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      setFilteredEventsWithDates(prevEvents => prevEvents.filter(event => event.event_id !== eventToDelete.event_id));
      setSelectedEvent(null);
      setSelectedDate(null);
      setEventsUpdated(prevState => !prevState);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/api/data/${userId}`)
      .then(response => {
        // Check if the response header indicates JSON content
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        } else {
          throw new Error('Received non-JSON response');
        }
      })
      .then(data => {
        console.log(data); // Log the data to verify its structure
        if (Array.isArray(data)) { // Ensure 'data' is an array before setting it
          setAssets(data);
        } else {
          console.error('Expected an array but received:', typeof data);
          setAssets([]); // Set to an empty array if data is not an array
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);;
  
  useEffect(() => {
    if (contact_id) {
      fetch(`${API_URL}/api/events/${contact_id}`)
        .then(response => response.json())
        .then(data => {
          const updatedEventsWithDates = data.map(event => {
            let dateWithoutTime = event.date.split('T')[0];
            let start = new Date(dateWithoutTime + 'T' + event.time);
            let end = new Date(dateWithoutTime + 'T' + event.time);
            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
              console.error('Invalid date format:', dateWithoutTime, event.time);
              return null;
            }
            start.setDate(start.getDate() + 1);
            end.setDate(end.getDate() + 1);
            return {
              ...event,
              title: event.title,
              start: start,
              end: end,
            };
          }).filter(event => event !== null);
          setFilteredEventsWithDates(updatedEventsWithDates);
        })
        .catch(error => console.error('Error:', error));
    }
  }, [contact_id, eventsUpdated]);

  const TouchCellWrapper = ({ children, value, onSelectSlot }) =>
    cloneElement(Children.only(children), {
      onTouchEnd: () => onSelectSlot({ action: "click", slots: [value] }),
      style: {
        className: `${children}`
      }
    });
    const onSelectSlot = ({ action, slots }) => {
      console.log("onSelectSlot");
      if (action === "click") {
        console.log("click");
        // Assuming the first slot in the array is the one we're interested in
        // and that handleSelectSlot expects an object with a start property.
        const slotInfo = { start: slots[0] }; // Adjust this based on actual structure
        handleSelectSlot(slotInfo);
      }
      return false;
    };
  return (
    <div>
      <IconButton onClick={() => setCalendarVisible(!calendarVisible)}>
        <CalendarTodayIcon style={{color: 'white', marginLeft: '40px'}}/>
      </IconButton>
      {calendarVisible && (
        <div className="calendar-container" style={{ position: 'relative' }}>
<Calendar
  localizer={localizer}
  events={filteredEventsWithDates}
  startAccessor="start"
  endAccessor="end"
  style={{ height: 500 }}
  selectable={true}
  onSelectEvent={handleSelect} // Use handleSelect for onSelectEvent
  onSelectSlot={handleSelectSlot} 
  components={{
    dateCellWrapper: (props) => (
      <TouchCellWrapper {...props} onSelectSlot={onSelectSlot} />
    )
  }}
/>
          {selectedDate && (
  <Paper style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1em',
    backgroundColor: '#1A2033',
    color: 'white',
    zIndex: 1000,
    width: window.innerWidth < 768 ? '90%' : '750px',
  }}>
  <form onSubmit={handleAddEvent}>
  <ThemeProvider theme={theme}>
  <TextField
        label="Date"
        type="text"
        value={selectedDate || ''}
        InputProps={{
          readOnly: true,
          style: { color: '#ffffff' },

        }}

        variant="outlined"
        margin="normal"
      />
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DesktopTimePicker
    label="Time"
    value={selectedTime ? dayjs(selectedTime, 'hh:mm A') : null} // Convert selectedTime to dayjs object
    className={classes.timePicker}
    onChange={(newValue) => {
      setSelectedTime(dayjs(newValue).format('hh:mm A')); // Convert newValue to 'hh:mm A' format
    }}
    onOpen={() => setIsTimePickerOpen(true)}
    onClose={() => setIsTimePickerOpen(false)}
    renderInput={(params) => <TextField {...params} ref={timePickerRef} />}
  />
</LocalizationProvider>
<TextField
  label="Event Title"
  type="text"
  name="title"
  value={eventTitle || ''} // Set the value prop
  InputProps={{
    style: { color: '#ffffff' },
  }}

  required
  variant="outlined"
  margin="normal"
  onChange={(e) => setEventTitle(e.target.value)}
/>
<Autocomplete
  disablePortal
  open={open}
  onOpen={() => {
    setOpen(true);
  }}
  onClose={() => {
    setOpen(false);
  }}
  options={assets}
  getOptionLabel={(option) => option.name}
  value={selectedAsset || null} // Set the value prop
  inputprops={{
    style: { color: '#ffffff' },
  }}

  onChange={(event, newValue) => {
    setSelectedAsset(newValue);
  }}
  renderInput={(params) => 
    <TextField 
      {...params} 
      className={classes.inputRoot}
      InputProps={{...params.InputProps,    style: { color: '#ffffff' },}} 

      label="Asset" 
      variant="outlined" 
      margin="normal" 
    />
  }
/>
<TextField
        label="Notes"
        type="text"
        value={notes || ''}
        InputProps={{
            style: { color: '#ffffff' },
        }}
        variant="outlined"
        margin="normal"
        onChange={(e) => setNotes(e.target.value)}
        fullWidth
      />
<IconButton type="submit" variant="contained"  style={{ margin: '1em 0', color: (!selectedDate || !selectedTime) ? 'gray' : 'white',  fontSize: '18px', fontFamily: 'Montserrat',  borderRadius: '5px' }} disabled={!selectedDate || !selectedTime}>
  {selectedEvent ? 'Update Event' : 'Add Event'}
</IconButton>
{selectedEvent && (
                <IconButton onClick={() => deleteEvent(selectedEvent)} style={{ margin: '1em 0', fontSize: '18px', fontFamily: 'Montserrat', color:'red',  borderRadius: '5px' }}>
                  Delete Event
                </IconButton>
              )}
    <IconButton 
    type="button" 
    onClick={() => setSelectedDate(null)} 
    style={{ position: 'absolute', right: 0, top: -5 }}
  >
    <CloseIcon style={{ color: 'white', fontSize: '20px' }}/>
  </IconButton>
  </ThemeProvider>
  </form>
            </Paper>
          )}
        </div>
      )}
    </div>
  );
}

export default EventCalendar;