import React, { useEffect, useState, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import FadersHorizontal from '../assets/FadersHorizontal';
import Bell from '../assets/Bell';
import ListMenu from '../assets/List';
import FilterComponent from './FilterComponent';
import Notifications from './Notifications';
import { Avatar, Button, Popover, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faUser, faLanguage } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from './LanguageSwitcher';
import Settings from './Settings';
import HandTap from '../assets/HandTap';
import HamburgerMenu from './HamburgerMenu';
import FilterContactsComponent from './FilterContactsComponent';
import ContactNotifications from './ContactNotifications';
import ContactActions from './ContactActions';
import AccountSettingsModal from './AccountSettingsModal';
import Badge from '@mui/material/Badge'; // Ensure you import Badge from Material-UI
import { useTranslation } from 'react-i18next';

const Sidebar = ({
  onDataFiltered,
  onPriceFiltered,
  onAssetTypeFiltered,
  onAmenitiesFiltered,
  onFurnitureFiltered,
  onSaleRentFiltered,
  onAssetConditionFiltered,
  onHeatingFiltered,
  onLanguageFiltered,
  clearAllFilters,
  onClearAllFilters,
  onFilterChanged,
  onAvailabilityFiltered,
  isLoggedIn, // User authentication status
  onLogout, // Logout function
  userId,
  username, 
  email, 
  setIsLoggedIn,
  showSettingsComponent,
  showContactActions,
  onClose,
  handleSelectionClick,
  handleContactSelectionClick,
  setShowSelectedOnly,
  setSelectedAssets,
  setSelectedMenuItem,
  selectedMenuItem,
  contactsData,
  setContactsData,
  setSelectedContacts,
  setShowSelectedContactsOnly,
  selectedContacts,
  displaySelectedContacts,
  displayContactOptions,
  displayedContactsCount,
  onDelete,
  assetTypes, 
  setAssetTypes,
  setAssets,
  onStatusFiltered,
  permissions,
  hiddenAssetsCount,
  onHiddenAssetsFiltered,
  assignModalOpen,
  setAssignModalOpen,
  notificationCount,
  updateNotificationCount,
  handleEnableNotifications,
  state,
  setState,
  isIOS
}) => {
  const [activeIcon, setActiveIcon] = useState(
    window.innerWidth >= 1100 ? 'faders' : '' // Set default active icon to 'faders' for screens wider than or equal to 1100px
  );
  const [topPosition, setTopPosition] = useState(
    window.innerWidth <= 1100 ? '130px' : '0px'
  );
  const [numberOfRooms, setNumberOfRooms] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [selectedAssetType, setSelectedAssetType] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const filterComponentRef = useRef(null);
  const filterContactsComponentRef = useRef(null);
  const notificationsRef = useRef(null);
  const contactActionsRef = useRef(null);
  const contactNotificationsRef = useRef(null);
  const HamburgerMenuRef = useRef(null);
  const settingsRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
    // State to control the visibility of LanguageSwitcher
    const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);
  // Function to toggle the LanguageSwitcher visibility
  const toggleLanguageSwitcher = () => {
    setShowLanguageSwitcher(!showLanguageSwitcher);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
    setAnchorEl(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

    // Update the active icon state based on the value of showSettingsComponent
    useEffect(() => {
      if (showSettingsComponent) {
        setActiveIcon('gear');
      } else {
        setActiveIcon(activeIcon);
      }
    }, [showSettingsComponent, activeIcon]);

    useEffect(() => {
      if (showContactActions) {
        setActiveIcon('gear');
      } else {
        setActiveIcon(activeIcon);
      }
    }, [showContactActions, activeIcon]);

  const handleIconClick = (iconName) => {
    setActiveIcon(iconName === activeIcon ? null : iconName);
    onClose();
  };

  useEffect(() => {
    const handleResize = () => {
      setTopPosition(window.innerWidth <= 1100 ? '130px' : '0px');
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);

  const [filterOrder, setFilterOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  useEffect(() => {
    if (userId) {
      const fetchFilterOrder = async () => {
        try {
          const response = await fetch(`${API_URL}/api/filterOrder/${userId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch filter order');
          }
          const data = await response.json();
          setFilterOrder(data.filterOrder);
        } catch (error) {
          console.error('Error fetching filter order:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchFilterOrder();
    }
  }, [userId]);

  // Render loading indicator while isLoading is true
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleLogout = () => {
    // Clear user's authentication state
    setIsLoggedIn(false);
    localStorage.removeItem('userId');
    console.log("you clicked on log out icon")
  };

   // Closing the popover
const handleClosePopover = () => {
  setActiveIcon('');
  setAnchorEl(null);
};
  // Function to handle profile button click and set the anchor element for popover
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseFilters = () => {
    setActiveIcon('');
    onClose();
  }
const open = Boolean(anchorEl);
const id = open ? 'sort-by-popover' : undefined;

  const renderContent = () => {
    if (isMobile) {
        // Placeholder for mobile content
        return (
          <>
        <div
        style={{
          height: '61px',
          left: '0',
          display: 'flex',
          flexDirection: 'row', // Change flex direction to row
          justifyContent: 'center', // Adjust alignment of items
          alignItems: 'center',
          position: 'fixed',
          zIndex: 1000,
          width: '100%',
          backgroundColor: '#0E1528',
          bottom: '0px'
        }}
    > 
      <List sx={{display: 'flex'}}>
      <ListItem sx={{ padding: '10px' }}>
        <IconButton onClick={() => handleIconClick('list')}>
          <ListMenu color={activeIcon === 'list' ? '#00D0BD' : '#ffffff'} width= '35px' height= '35px'/>
        </IconButton>
      </ListItem>
        <ListItem sx={{padding: '10px'}}>
            <Badge 
             badgeContent={notificationCount}
            sx={{ '& .MuiBadge-badge': { backgroundColor: 'rgb(0, 208, 189)', marginRight: '2px', marginTop: '5px' } }} // Custom style for badge color
          >
            <IconButton onClick={() => handleIconClick('bell')}>
              <Bell color={activeIcon === 'bell' ? '#00D0BD' : '#ffffff'} />
            </IconButton>
          </Badge>
        </ListItem>
        <ListItem sx={{padding: '10px'}}>
          <IconButton onClick={() => handleIconClick('gear')}>
            <HandTap color={activeIcon === 'gear' ? '#00D0BD' : '#ffffff'} />
          </IconButton>
        </ListItem>
        <IconButton sx={{padding: '10px'}} onClick={handleProfileClick} >
          <Avatar sx={{ bgcolor: '#0E1529', width: '25px', height: '25px', border: '1px solid white' }}> {/* User icon */}
            {/* You can also put an actual user image here */}
            <FontAwesomeIcon icon={faUser} style={{width:'20px', height: '20px'}} />
        </Avatar>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{
            top: '-50px',
            position: 'absolute' 
          }}
        >
        <List style={{ background: 'linear-gradient(180deg, #2A2F3D 100%, rgba(65, 0, 51, 0.00) 100%)', flexDirection: 'column', display: 'flex', width: '195px', }}>
        <Typography style={{ color: '#FFFFFF', textTransform: 'none', fontSize: '12px', paddingLeft: '5px', fontFamily: 'Montserrat' }}>
        email: {email}
        </Typography>
        {/* Display user's username */}
        <Typography style={{ color: '#FFFFFF', textTransform: 'none', fontSize: '12px' , paddingLeft: '5px', fontFamily: 'Montserrat'}}>
          username: {username}
        </Typography>
        <div style={{ borderTop: '1px solid gray', marginTop: '10px', marginBottom: '10px' }}></div>
        {/* Buttons */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button style={{ color: '#FFFFFF', textTransform: 'none', fontFamily: 'Montserrat' }} className="my-button" onClick={handleOpenModal}>
        <FontAwesomeIcon icon={faCog} style={{ marginRight: '5px', }} />
          Account settings
        </Button>
        <Button style={{ color: '#FFFFFF', textTransform: 'none' , fontFamily: 'Montserrat'}} className="my-button" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
          Log Out
        </Button>
        <Button style={{ color: '#FFFFFF', textTransform: 'none' , fontSize: '13px',  fontFamily: 'Montserrat'}} className="my-button" onClick={toggleLanguageSwitcher}>
      <FontAwesomeIcon icon={faLanguage} style={{ marginRight: '5px',  }}/>
      {t('Language preference')}
    </Button>
    {showLanguageSwitcher && <LanguageSwitcher setAnchorEl={setAnchorEl} setShowLanguageSwitcher={setShowLanguageSwitcher} />}
      </div>
        </List>
        </Popover>
        {isModalOpen && <AccountSettingsModal onClose={handleCloseModal} email={email} username={username} />}
      </List>
                {activeIcon === 'list' && (
            <div ref={HamburgerMenuRef}>
              <HamburgerMenu setSelectedMenuItem={setSelectedMenuItem} selectedMenuItem={selectedMenuItem} handleIconClick={handleIconClick}/> 
            </div>
          )}
          {activeIcon === 'bell' && (
            <div ref={notificationsRef}>
              <Notifications  updateNotificationCount={updateNotificationCount} handleEnableNotifications={handleEnableNotifications} isIOS={isIOS}/>
            </div>
          )}
                    {activeIcon === 'gear' && (selectedMenuItem === 'contacts' ? 
          <div ref={contactActionsRef}>
            <ContactActions  onDelete={onDelete} contactsData = {contactsData} handleContactSelectionClick = {handleContactSelectionClick} displayContactOptions={displayContactOptions}  displayedContactsCount={displayedContactsCount} selectedContacts = {selectedContacts} setContactsData={setContactsData} userId={userId} setSelectedContacts={setSelectedContacts} setShowSelectedContactsOnly={setShowSelectedContactsOnly}/>
          </div>
        : 
          <div ref={settingsRef}>
            <Settings  userId={userId}  setAssets={setAssets} handleSelectionClick={handleSelectionClick} setShowSelectedOnly={setShowSelectedOnly} setSelectedAssets={setSelectedAssets} assetTypes={assetTypes} permissions={permissions} assignModalOpen={assignModalOpen} setAssignModalOpen={setAssignModalOpen} />
          </div>
        )}
                    {activeIcon === 'faders' && (selectedMenuItem === 'contacts' ? 
          <div ref={filterContactsComponentRef}>
            <FilterContactsComponent />
          </div>
        : 
  <div ref={filterComponentRef}>
    <FilterComponent
              state={state}
              setState={setState}
      numberOfRooms={numberOfRooms}
      setNumberOfRooms={setNumberOfRooms}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      assetTypes={assetTypes}
      setAssetTypes={setAssetTypes}
      selectedAssetType={selectedAssetType}
      setSelectedAssetType={setSelectedAssetType}
      checkedItems={checkedItems}
      setCheckedItems={setCheckedItems}
      onDataFiltered={onDataFiltered}
      onPriceFiltered={onPriceFiltered}
      onAssetTypeFiltered={onAssetTypeFiltered}
      onAmenitiesFiltered={onAmenitiesFiltered}
      onFurnitureFiltered={onFurnitureFiltered}
      onSaleRentFiltered={onSaleRentFiltered}
      onAssetConditionFiltered={onAssetConditionFiltered}
      onAvailabilityFiltered={onAvailabilityFiltered}
      onHeatingFiltered={onHeatingFiltered}
      onStatusFiltered={onStatusFiltered}
      onHiddenAssetsFiltered={onHiddenAssetsFiltered}
      onLanguageFiltered={onLanguageFiltered}
      clearAllFilters={clearAllFilters}
      onClearAllFilters={onClearAllFilters}
      onFilterChanged={onFilterChanged}
      userId={userId} filterOrder={filterOrder} setFilterOrder={setFilterOrder}
      hiddenAssetsCount={hiddenAssetsCount}
      onClose={onCloseFilters}
    />
  </div>
)}
    </div>

    <div style={{ position: 'fixed', top: '55px', right: '5px', zIndex: '999' }}>
    <IconButton onClick={() => handleIconClick('faders')}>
      <FadersHorizontal color={activeIcon === 'faders' ? '#00D0BD' : '#ffffff'} />
    </IconButton>
  </div>
    </>
        );
    } else {
        // Placeholder for desktop content
        return (
          <div
          style={{
            height: '100vh',
            width: '60px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            top: topPosition,
            position: 'fixed',
            zIndex: 1000,
            left: '0px',
            borderRight: '1px solid #2A2F3D',
          }}
        >
            <List >
          <ListItem sx={{ marginBottom: '60px', padding: 1, justifyContent: 'center',}}>
            <IconButton onClick={() => handleIconClick('list')} >
            <ListMenu color={activeIcon === 'list' ? '#00D0BD' : '#ffffff'} width= '56px' height= '56px'/>
            </IconButton>

          </ListItem>
          </List>
          <List >
            <ListItem sx={{paddingBottom: '8px', paddingTop: '8px',}}>
              <IconButton onClick={() => handleIconClick('faders')}>
                <FadersHorizontal color={activeIcon === 'faders' ? '#00D0BD' : '#ffffff'} />
              </IconButton>
            </ListItem>
            <ListItem sx={{ paddingBottom: '8px', paddingTop: '8px' }}>
            <Badge
            badgeContent={Math.max(0, notificationCount)}
            sx={{ '& .MuiBadge-badge': { backgroundColor: 'rgb(0, 208, 189)', marginRight: '12px', marginTop: '5px' } }} // Custom style for badge color
          >
            <IconButton onClick={() => handleIconClick('bell')}>
              <Bell color={activeIcon === 'bell' ? '#00D0BD' : '#ffffff'} />
            </IconButton>
          </Badge>
          </ListItem>
            <ListItem sx={{paddingBottom: '8px', paddingTop: '8px'}}>
              <IconButton onClick={() => handleIconClick('gear')}>
                <HandTap color={activeIcon === 'gear' ? '#00D0BD' : '#ffffff'} />
              </IconButton>
            </ListItem>
            
          </List>
          {activeIcon === 'list' && (
              <div ref={HamburgerMenuRef}>
                <HamburgerMenu setSelectedMenuItem={setSelectedMenuItem} selectedMenuItem={selectedMenuItem} handleIconClick={handleIconClick}/> 
              </div>
            )}
            {activeIcon === 'bell' && (
              <div ref={notificationsRef}>
                <Notifications updateNotificationCount={updateNotificationCount}/>
              </div>
            )}
                    {activeIcon === 'gear' && (selectedMenuItem === 'contacts' ? 
          <div ref={contactActionsRef}>
            <ContactActions  onDelete={onDelete} contactsData = {contactsData} handleContactSelectionClick = {handleContactSelectionClick} displayContactOptions={displayContactOptions}  displayedContactsCount={displayedContactsCount} selectedContacts = {selectedContacts} setContactsData={setContactsData} userId={userId} setSelectedContacts={setSelectedContacts} setShowSelectedContactsOnly={setShowSelectedContactsOnly}/>
          </div>
        : 
          <div ref={settingsRef}>
            <Settings  userId={userId} setAssets={setAssets} handleSelectionClick={handleSelectionClick} setShowSelectedOnly={setShowSelectedOnly} setSelectedAssets={setSelectedAssets} assetTypes={assetTypes} permissions={permissions} setAssignModalOpen={setAssignModalOpen} assignModalOpen={assignModalOpen}/>
          </div>
        )}
                    {activeIcon === 'faders' && (selectedMenuItem === 'contacts' ? 
          <div ref={filterContactsComponentRef}>
            <FilterContactsComponent />
          </div>
        : 
  <div ref={filterComponentRef}>
    <FilterComponent
              state={state}
              setState={setState}
      numberOfRooms={numberOfRooms}
      setNumberOfRooms={setNumberOfRooms}
      priceRange={priceRange}
      setPriceRange={setPriceRange}
      assetTypes={assetTypes}
      setAssetTypes={setAssetTypes}
      selectedAssetType={selectedAssetType}
      setSelectedAssetType={setSelectedAssetType}
      checkedItems={checkedItems}
      setCheckedItems={setCheckedItems}
      onDataFiltered={onDataFiltered}
      onPriceFiltered={onPriceFiltered}
      onAssetTypeFiltered={onAssetTypeFiltered}
      onAmenitiesFiltered={onAmenitiesFiltered}
      onFurnitureFiltered={onFurnitureFiltered}
      onSaleRentFiltered={onSaleRentFiltered}
      onAssetConditionFiltered={onAssetConditionFiltered}
      onAvailabilityFiltered={onAvailabilityFiltered}
      onHeatingFiltered={onHeatingFiltered}
      onStatusFiltered={onStatusFiltered}
      onHiddenAssetsFiltered={onHiddenAssetsFiltered}
      onLanguageFiltered={onLanguageFiltered}
      clearAllFilters={clearAllFilters}
      onClearAllFilters={onClearAllFilters}
      onFilterChanged={onFilterChanged}
      userId={userId} filterOrder={filterOrder} setFilterOrder={setFilterOrder}
      hiddenAssetsCount={hiddenAssetsCount}
    />
  </div>
)}
        </div>
        );
    }
};

return (
    <React.Fragment>
        {renderContent()}
    </React.Fragment>
);
};

export default Sidebar;
